<div class="adc-linear-progress__buffer" aria-hidden="true">
  <div class="adc-linear-progress__buffer-bar"></div>
</div>
<div
  class="adc-linear-progress__bar adc-linear-progress__primary-bar"
  aria-hidden="true"
  [style.transform]="_getPrimaryBarTransform()"
>
  <span class="adc-linear-progress__bar-inner"></span>
</div>
