import { Component, ElementRef, ViewEncapsulation } from '@angular/core';

/** List of classes to add to buttons instances based on host attribute selector. */
const HOST_SELECTOR_ADC_CLASS_PAIR: { attribute: string; variantClasses: string[] }[] = [
  {
    attribute: 'full-width',
    variantClasses: ['ids-button-group', 'ids-full-width-button-group'],
  },
];

@Component({
  selector: `ids-button-group, ids-button-group[full-width]`,
  standalone: true,
  host: {
    class: 'ids-button-group',
    role: 'group',
  },
  exportAs: 'idsButtonGroup',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./button-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IdsButtonGroupComponent {
  constructor(public _elementRef: ElementRef) {
    const element = _elementRef.nativeElement;
    const classList = (element as HTMLElement).classList;

    for (const { attribute, variantClasses } of HOST_SELECTOR_ADC_CLASS_PAIR) {
      if (element.hasAttribute(attribute)) {
        classList.add(...variantClasses);
      }
    }
  }
}
