<a
  [attr.id]="id"
  class="nav-item-link-wrapper"
  [ngClass]="setNavItemState()"
  [routerLink]="url"
  routerLinkActive="active"
  (click)="onNavItemNavigate($event)"
>
  <div [class.rounded]="this.rounded" class="nav-item-container" [attr.aria-disabled]="disabled">
    <div class="nav-item">
      <ng-content select="[counter]"></ng-content>
      <ng-content select="[icon]"></ng-content>

      <span class="nav-item-label">
        <ng-content></ng-content>
      </span>
    </div>

    @if (expandable) {
      <span [@indicatorRotate]="getExpandedState()" class="nav-icon-chevron">
        <i idsIcon icon="chevron-down"></i>
      </span>
    }
  </div>
</a>
