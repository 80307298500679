import { DEFAULT_IDLE_TIEMOUT_IN_SECONDS } from '@abbadox-monorepo/kiosk-core-constants';
import { inject, Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { IDLE_STATES, IdleActions, IdleState } from './idle.state';

/**
 * Service to control the idling state of the app.
 *
 * Derivative of the following service to use with NgRx.
 * @source https://github.com/moribvndvs/ng2-idle/issues/108
 */
@Injectable({
  providedIn: 'root',
})
export class IdleService {
  private isSetup = false;

  private readonly idle = inject(Idle);
  private readonly store = inject(Store<IdleState>);

  /**
   * Checks to see if idle service is currently running
   */
  isServiceRunning(): boolean {
    return this.idle.isRunning();
  }

  /**
   * Starts the idle service
   */
  startIdling(): void {
    if (this.idle.isRunning()) {
      this.idle.stop();
    }

    this.idle.watch();
  }

  /**
   * Stops the idle services
   */
  stopIdling(): void {
    this.idle.stop();
  }

  /**
   * Sets up the idle service's defaults
   */
  setup(params: Partial<IdleState>) {
    if (!this.isSetup) {
      this.idle.setIdle(params.idleTimeoutSeconds ?? DEFAULT_IDLE_TIEMOUT_IN_SECONDS); // how long can they be inactive before considered idle, in seconds
      this.idle.setTimeout(params.idleTimeoutSeconds ?? DEFAULT_IDLE_TIEMOUT_IN_SECONDS); // how long can they be idle before considered timed out, in seconds
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

      // do something when the user becomes idle
      this.idle.onIdleStart.subscribe(() => {
        this.store.dispatch(IdleActions.setIdleState({ idleState: IDLE_STATES.IDLE }));
      });

      // do something when the user is no longer idle
      this.idle.onIdleEnd.subscribe(() => {
        this.store.dispatch(IdleActions.setIdleState({ idleState: IDLE_STATES.NOT_IDLE }));
      });

      this.idle.onTimeout.subscribe(() => {
        this.store.dispatch(IdleActions.setIdleState({ idleState: IDLE_STATES.TIMED_OUT }));
        this.store.dispatch(IdleActions.triggerTimeoutDialogOpen());
      });

      this.isSetup = true;
    }
  }
}
