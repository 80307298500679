import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  numberAttribute,
  OnDestroy,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';

/** Last animation end data. */
export interface ProgressAnimationEnd {
  value: number;
}

/** Default `ids-progress-bar` options that can be overridden. */
export interface IdsProgressBarDefaultOptions {
  /** Default mode of the progress bar. */
  mode?: ProgressBarMode;
}

/** Injection token to be used to override the default options for `ids-progress-bar`. */
export const IDS_PROGRESS_BAR_DEFAULT_OPTIONS = new InjectionToken<IdsProgressBarDefaultOptions>(
  'IDS_PROGRESS_BAR_DEFAULT_OPTIONS',
);

export type ProgressBarMode = 'determinate' | 'indeterminate' | 'query';

const IDS_PROGRESS_BAR_HOST = {
  role: 'progressbar',
  'aria-valuemin': '0',
  'aria-valuemax': '100',
  // set tab index to -1 so screen readers will read the aria-label
  // Note: there is a known issue with JAWS that does not read progressbar aria labels on FireFox
  tabindex: '-1',
  '[attr.aria-valuenow]': '_isIndeterminate() ? null : value',
  '[attr.mode]': 'mode',
  class: 'ids-adc-progress-bar adc-linear-progress',
  '[class]': '"ids" + color',
  '[class.adc-linear-progress--animation-ready]': '!_isNoopAnimation',
  '[class.adc-linear-progress--indeterminate]': '_isIndeterminate()',
};

@Component({
  selector: 'ids-progress-bar',
  standalone: true,
  host: IDS_PROGRESS_BAR_HOST,
  exportAs: 'idsProgressBar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IdsProgressBar implements AfterViewInit, OnDestroy {
  constructor(
    readonly _elementRef: ElementRef<HTMLElement>,
    private _ngZone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(IDS_PROGRESS_BAR_DEFAULT_OPTIONS) defaults?: IdsProgressBarDefaultOptions,
  ) {
    if (defaults) {
      this.mode = defaults.mode || this.mode;
    }
  }

  @Input()
  get color() {
    return this._color;
  }
  set color(value: string | null | undefined) {
    this._color = value;
  }
  private _color: string | null | undefined;

  /** Value of the progress bar. Defaults to zero. Mirrored to aria-valuenow. */
  @Input({ transform: numberAttribute })
  get value(): number {
    return this._value;
  }
  set value(v: number) {
    this._value = clamp(v || 0);
    this._changeDetectorRef.markForCheck();
  }
  private _value = 0;

  /**
   * Event emitted when animation of the primary progress bar completes. This event will not be emitted for modes with continuous
   * animations (indeterminate and query).
   */
  @Output() readonly animationEnd = new EventEmitter<ProgressAnimationEnd>();

  /**
   * Mode of the progress bar.
   *
   * Input must be one of these values: determinate, indeterminate, buffer, query, defaults to
   * 'determinate'.
   * Mirrored to mode attribute.
   */
  @Input()
  get mode(): ProgressBarMode {
    return this._mode;
  }
  set mode(value: ProgressBarMode) {
    // Note that we don't technically need a getter and a setter here,
    // but we use it to match the behavior of the existing ids-progress-bar.
    this._mode = value;
    this._changeDetectorRef.markForCheck();
  }
  private _mode: ProgressBarMode = 'determinate';

  ngAfterViewInit() {
    // Run outside angular so change detection didn't get triggered on every transition end
    // instead only on the animation that we care about (the primary value bar's transitionend)
    this._ngZone.runOutsideAngular(() => {
      this._elementRef.nativeElement.addEventListener('transitionend', this._transitionendHandler);
    });
  }

  ngOnDestroy() {
    this._elementRef.nativeElement.removeEventListener('transitionend', this._transitionendHandler);
  }

  /** Gets the transform style that should be applied to the primary bar. */
  _getPrimaryBarTransform(): string {
    return `scaleX(${this._isIndeterminate() ? 1 : this.value / 100})`;
  }

  /** Returns whether the progress bar is indeterminate. */
  _isIndeterminate(): boolean {
    return this.mode === 'indeterminate' || this.mode === 'query';
  }

  /** Event handler for `transitionend` events. */
  private _transitionendHandler = (event: TransitionEvent) => {
    if (
      this.animationEnd.observers.length === 0 ||
      !event.target ||
      !(event.target as HTMLElement).classList.contains('adc-linear-progress__primary-bar')
    ) {
      return;
    }

    if (this.mode === 'determinate') {
      this._ngZone.run(() => this.animationEnd.next({ value: this.value }));
    }
  };
}

/** Clamps a value to be between two numbers, by default 0 and 100. */
function clamp(v: number, min = 0, max = 100) {
  return Math.max(min, Math.min(max, v));
}
