import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerActions, ErrorHandlerState } from './error.state';

/**
 * Error handler interceptor middleware that listens on error produced by any API requests.
 */
export const errorHandlingInterceptor = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const errorHandlerStore = inject(Store<ErrorHandlerState>);

  return next(request).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 401:
            errorHandlerStore.dispatch(
              ErrorHandlerActions.handleError401({ message: error.message, status: error.status }),
            );
            break;
          case 500:
            errorHandlerStore.dispatch(
              ErrorHandlerActions.handleError500({ message: error.message, status: error.status }),
            );
            break;
          default:
            throwError(() => new Error(JSON.stringify(error)));
            break;
        }
      }

      return throwError(() => new Error(JSON.stringify(error)));
    }),
  );
};
