import {
  ANIMATION_MODULE_TYPE,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  NgZone,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { IDS_BUTTON_HOST, IdsButtonBase } from './button-base.directive';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: `
    button[ids-adc-button], button[ids-outline-button],
    button[ids-ghost-button], button[ids-slim-button],
    button[rounded-full], button[rounded-left],
    button[rounded-right], button[rounded-none],
    button[full-width]
  `,
  standalone: true,
  host: IDS_BUTTON_HOST,
  exportAs: 'idsButton',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdsButton extends IdsButtonBase {
  constructor(
    elementRef: ElementRef,
    platform: Platform,
    ngZone: NgZone,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) animationMode?: string,
  ) {
    super(elementRef, platform, ngZone, animationMode);
  }
}
