import { IdsToastConfig, NotificationData } from '@abbadox-monorepo/shared-ui';
import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from '@ngrx/store';

export const CoreUiActions = createActionGroup({
  source: 'Core UI',
  events: {
    loadingInit: emptyProps(),
    loadingComplete: emptyProps(),
    triggerToastOpen: props<{ data: NotificationData; options?: Omit<IdsToastConfig, 'data'> }>(),
    triggerToastClose: emptyProps(),
  },
});

export interface CoreUiState {
  toast: boolean;
  spinner: boolean;
}

export const initialCoreUiState: CoreUiState = {
  toast: false,
  spinner: false,
};

export const coreUiFeature = createFeature({
  name: 'core',
  reducer: createReducer(
    initialCoreUiState,
    on(CoreUiActions.loadingInit, (state) => ({
      ...state,
      spinner: true,
    })),
    on(CoreUiActions.loadingComplete, (state) => ({
      ...state,
      spinner: false,
    })),
    on(CoreUiActions.triggerToastOpen, (state) => ({
      ...state,
      toast: true,
    })),
    on(CoreUiActions.triggerToastClose, (state) => ({
      ...state,
      toast: false,
    })),
  ),
});

export const { name: CORE_UI_STATE_FEATURE_KEY, reducer: coreReducer, selectSpinner, selectToast } = coreUiFeature;
