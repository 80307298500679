import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrl } from './custom-serializer';

// Custom router selectors to relpace ngrx `getRouterSelectors` because the defaults don't work with a custom serializer.
const selectRouter = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');
const selectCurrentRoute = createSelector(selectRouter, (router) => router?.state);

/**
 * Gets the query params from a route.
 *
 * @example
 * A route with `/path?filter='all'` will return `{ filter: 'all' }`.
 */
const selectQueryParams = createSelector(selectRouter, (router) => router?.state.queryParams);

/**
 * Gets all the params from a route.
 *
 * @example
 * A defined route as `/resource/:id` navigated like `/resource/123 will pull values as `{ id: '123' }`
 */
const selectRouteParams = createSelector(selectRouter, (router) => router?.state.params);

/**
 * Gets the value of the `data` property from a route.
 *
 * @example
 * A route with `{ data: { someKey: 'value' } }` will return `{ someKey: 'value' }`
 */
const selectRouteData = createSelector(selectRouter, (router) => router?.state.data);

/**
 * Gets the path starting from the root `/` down to the route path where this was called.
 */
const selectUrl = createSelector(selectRouter, (router) => router?.state.url);

export { selectCurrentRoute, selectQueryParams, selectRouteParams, selectRouteData, selectUrl };
