import { FocusMonitor } from '@angular/cdk/a11y';
import { ENTER } from '@angular/cdk/keycodes';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation,
  afterNextRender,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { IdsChipAction } from './chip-action.directive';
import { IdsChipEditInput } from './chip-edit-input.directive';
import { IDS_CHIP } from './chip-tokens';
import { IdsChip, IdsChipEvent } from './chip.component';

/** Represents an event fired on an individual `ids-chip` when it is edited. */
export interface IdsChipEditedEvent extends IdsChipEvent {
  /** The final edit value. */
  value: string;
}

/**
 * An extension of the IdsChip component used with IdsChipGrid and
 * the matChipInputFor directive.
 */
@Component({
  selector: 'ids-chip-row, [ids-chip-row], ids-basic-chip-row, [ids-basic-chip-row]',
  templateUrl: './chip-row.component.html',
  styleUrl: './chip.component.scss',
  host: {
    class: 'ids-adc-chip ids-adc-chip-row adc-evolution-chip',
    '[class.ids-adc-chip-with-avatar]': 'leadingIcon',
    '[class.ids-adc-chip-disabled]': 'disabled',
    '[class.ids-adc-chip-editing]': '_isEditing',
    '[class.ids-adc-chip-editable]': 'editable',
    '[class.adc-evolution-chip--disabled]': 'disabled',
    '[class.adc-evolution-chip--with-trailing-action]': '_hasTrailingIcon()',
    '[class.adc-evolution-chip--with-primary-graphic]': 'leadingIcon',
    '[class.adc-evolution-chip--with-primary-icon]': 'leadingIcon',
    '[class.adc-evolution-chip--with-avatar]': 'leadingIcon',
    '[class.ids-adc-chip-highlighted]': 'highlighted',
    '[class.ids-adc-chip-with-trailing-icon]': '_hasTrailingIcon()',
    '[id]': 'id',
    // Has to have a negative tabindex in order to capture
    // focus and redirect it to the primary action.
    '[attr.tabindex]': 'disabled ? null : -1',
    '[attr.aria-label]': 'null',
    '[attr.aria-description]': 'null',
    '[attr.role]': 'role',
    '(focus)': '_handleFocus($event)',
    '(dblclick)': '_handleDoubleclick($event)',
  },
  providers: [
    { provide: IdsChip, useExisting: IdsChipRow },
    { provide: IDS_CHIP, useExisting: IdsChipRow },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IdsChipAction, IdsChipEditInput],
})
export class IdsChipRow extends IdsChip implements AfterViewInit {
  protected override basicChipAttrName = 'ids-basic-chip-row';

  /**
   * The editing action has to be triggered in a timeout. While we're waiting on it, a blur
   * event might occur which will interrupt the editing. This flag is used to avoid interruptions
   * while the editing action is being initialized.
   */
  private _editStartPending = false;

  @Input() editable: boolean = false;

  /** Emitted when the chip is edited. */
  @Output() readonly edited: EventEmitter<IdsChipEditedEvent> = new EventEmitter<IdsChipEditedEvent>();

  /** The default chip edit input that is used if none is projected into this chip row. */
  @ViewChild(IdsChipEditInput) defaultEditInput?: IdsChipEditInput;

  /** The projected chip edit input. */
  @ContentChild(IdsChipEditInput) contentEditInput?: IdsChipEditInput;

  _isEditing = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    ngZone: NgZone,
    focusMonitor: FocusMonitor,
    @Inject(DOCUMENT) _document: any,
    @Optional() @Attribute('tabindex') tabIndex?: string,
  ) {
    super(changeDetectorRef, elementRef, ngZone, focusMonitor, _document, tabIndex);

    this.role = 'row';
    this._onBlur.pipe(takeUntil(this.destroyed)).subscribe(() => {
      if (this._isEditing && !this._editStartPending) {
        this._onEditFinish();
      }
    });
  }

  override _hasTrailingIcon() {
    // The trailing icon is hidden while editing.
    return !this._isEditing && super._hasTrailingIcon();
  }

  /** Sends focus to the first gridcell when the user clicks anywhere inside the chip. */
  _handleFocus() {
    if (!this._isEditing && !this.disabled) {
      this.focus();
    }
  }

  override _handleKeydown(event: KeyboardEvent): void {
    if (event.keyCode === ENTER && !this.disabled) {
      if (this._isEditing) {
        event.preventDefault();
        this._onEditFinish();
      } else if (this.editable) {
        this._startEditing(event);
      }
    } else if (this._isEditing) {
      // Stop the event from reaching the chip set in order to avoid navigating.
      event.stopPropagation();
    } else {
      super._handleKeydown(event);
    }
  }

  _handleDoubleclick(event: MouseEvent) {
    if (!this.disabled && this.editable) {
      this._startEditing(event);
    }
  }

  private _startEditing(event: Event) {
    if (!this.primaryAction || (this.removeIcon && this._getSourceAction(event.target as Node) === this.removeIcon)) {
      return;
    }

    // The value depends on the DOM so we need to extract it before we flip the flag.
    const value = this.value;

    this._isEditing = this._editStartPending = true;

    // Defer initializing the input until after it has been added to the DOM.
    afterNextRender(
      () => {
        this._getEditInput().initialize(value);
        this._editStartPending = false;
      },
      { injector: this._injector },
    );
  }

  private _onEditFinish() {
    this._isEditing = this._editStartPending = false;
    this.edited.emit({ chip: this, value: this._getEditInput().getValue() });

    // If the edit input is still focused or focus was returned to the body after it was destroyed,
    // return focus to the chip contents.
    if (
      this._document.activeElement === this._getEditInput().getNativeElement() ||
      this._document.activeElement === this._document.body
    ) {
      this.primaryAction.focus();
    }
  }

  /**
   * Gets the projected chip edit input, or the default input if none is projected in. One of these
   * two values is guaranteed to be defined.
   */
  private _getEditInput(): IdsChipEditInput {
    return this.contentEditInput || this.defaultEditInput!;
  }
}
