import { ENTER } from '@angular/cdk/keycodes';
import { InjectionToken } from '@angular/core';

/** Default options, for the chips module, that can be overridden. */
export interface IdsChipsDefaultOptions {
  /** The list of key codes that will trigger a chipEnd event. */
  separatorKeyCodes: readonly number[] | ReadonlySet<number>;

  /** Wheter icon indicators should be hidden for single-selection. */
  hideSingleSelectionIndicator?: boolean;
}

/** Injection token to be used to override the default options for the chips module. */
export const IDS_CHIPS_DEFAULT_OPTIONS = new InjectionToken<IdsChipsDefaultOptions>('ids-chips-default-options', {
  providedIn: 'root',
  factory: () => ({
    separatorKeyCodes: [ENTER],
  }),
});

/**
 * Injection token that can be used to reference instances of `IdsChipAvatar`. It serves as
 * alternative token to the actual `IdsChipAvatar` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const IDS_CHIP_AVATAR = new InjectionToken('IdsChipAvatar');

/**
 * Injection token that can be used to reference instances of `IdsChipTrailingIcon`. It serves as
 * alternative token to the actual `IdsChipTrailingIcon` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const IDS_CHIP_TRAILING_ICON = new InjectionToken('IdsChipTrailingIcon');

/**
 * Injection token that can be used to reference instances of `IdsChipRemove`. It serves as
 * alternative token to the actual `IdsChipRemove` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const IDS_CHIP_REMOVE = new InjectionToken('IdsChipRemove');

/**
 * Injection token used to avoid a circular dependency between the `IdsChip` and `IdsChipAction`.
 */
export const IDS_CHIP = new InjectionToken('IdsChip');
