import { Directive, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * A directive that makes a span editable and exposes functions to modify and retrieve the
 * element's contents.
 */
@Directive({
  selector: 'span[idsChipEditInput]',
  host: {
    class: 'ids-chip-edit-input',
    role: 'textbox',
    tabindex: '-1',
    contenteditable: 'true',
  },
  standalone: true,
})
export class IdsChipEditInput {
  constructor(
    private readonly _elementRef: ElementRef,
    @Inject(DOCUMENT) private readonly _document: any,
  ) {}

  initialize(initialValue: string) {
    this.getNativeElement().focus();
    this.setValue(initialValue);
  }

  getNativeElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  setValue(value: string) {
    this.getNativeElement().textContent = value;
    this._moveCursorToEndOfInput();
  }

  getValue(): string {
    return this.getNativeElement().textContent || '';
  }

  private _moveCursorToEndOfInput() {
    const range = this._document.createRange();
    range.selectNodeContents(this.getNativeElement());
    range.collapse(false);
    const sel = window.getSelection()!;
    sel.removeAllRanges();
    sel.addRange(range);
  }
}
