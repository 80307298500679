import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
//@ts-ignore
import moment from 'moment';

export function dateValidator(isDob = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dateRegex = /^(0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)(\d){2}/i;
    const value = control.value;

    const result = dateRegex.test(value);
    const date = moment(value, 'MM/DD/YYYY');

    if (result && date.isValid() && !isDob) {
      return null;
    } else if (result && date.isValid() && isDob) {
      const today = moment();
      if (today.isAfter(date)) {
        return null;
      }
    }

    return { dateValidator: { value, message: 'Enter date format MM/DD/YYYY or valid Date' } };
  };
}

export const typeDateValidate = (text: string): string | null => {
  let dateRegex;

  if (!text) {
    return '';
  }

  dateRegex = /^[2-9]/i;
  if (dateRegex.test(text)) {
    dateRegex = /^[2-9][/]/i;
    text = dateRegex.test(text) ? `0${text}` : `0${text}/`;
    return validateDateText(text);
  }

  dateRegex = /^[1][/]$/i;
  if (dateRegex.test(text)) {
    text = `0${text}`;
    return validateDateText(text);
  }

  dateRegex = /^(0([1-9])?|1([012])?)$/i;
  if (dateRegex.test(text)) {
    return validateDateText(text);
  }

  dateRegex = /^(0([1-9])|1([012]))([0123])/i;
  if (dateRegex.test(text)) {
    const begin = text.slice(0, 2);
    const end = text.slice(2);
    text = `${begin}/${end}`;
    return validateDateText(text);
  }

  dateRegex = /^(0([1-9])|1([012]))[/]$/i;
  if (dateRegex.test(text)) {
    return validateDateText(text);
  }

  dateRegex = /^(0([1-9])|1([012]))[/](0[1-9]?|[12][0-9]?|3[01]?)$/i;
  if (dateRegex.test(text)) {
    return validateDateText(text);
  }

  dateRegex = /^(0([1-9])|1([012]))[/](0[1-9]?|[12][0-9]?|3[01]?)[/]$/i;
  if (dateRegex.test(text)) {
    return validateDateText(text);
  }

  dateRegex = /^(0([1-9])|1([012]))[/](0[1-9]|[12][0-9]|3[01])(1|2)$/i;
  if (dateRegex.test(text)) {
    const begin = text.slice(0, 5);
    const end = text.slice(5);
    text = `${begin}/${end}`;
    return validateDateText(text);
  }

  dateRegex = /^(0([1-9])|1([012]))[/](0[1-9]?|[12][0-9]?|3[01]?)[/](1|2)$/i;
  if (dateRegex.test(text)) {
    return validateDateText(text);
  }

  dateRegex = /^(0([1-9])|1([012]))[/](0[1-9]?|[12][0-9]?|3[01]?)[/](19|20)(\d){0,2}$/i;
  if (dateRegex.test(text)) {
    return validateDateText(text);
  }

  dateRegex = /^([/]|[/][/])$/i;
  if (dateRegex.test(text)) {
    return validateDateText(text);
  }
  dateRegex = /^((0([1-9])?|1([012])?)[/][/])$/i;
  if (dateRegex.test(text)) {
    return validateDateText(text);
  }
  dateRegex = /^((0([1-9])?|1([012])?)[/][/](19|20)(\d){0,2})$/i;
  if (dateRegex.test(text)) {
    return validateDateText(text);
  }
  dateRegex = /^((0?([1-9])?|1?([012])?)[/](0[1-9]?|[12][0-9]?|3[01]?)[/](19|20)(\d){0,2})$/i;
  if (dateRegex.test(text)) {
    return validateDateText(text);
  }

  return null;
};

const validateDateText = (text: string) => {
  const dateRegex = /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)(\d){2}/i;
  let date = moment(text, 'MM/DD/YYYY');
  if (dateRegex.test(text)) {
    if (date.isValid()) {
      return date.format('MM/DD/YYYY');
    } else {
      const arr = text.split('/');
      let day = parseInt(arr[1], 10);
      if (day) {
        while (day > 0) {
          --day;
          text = `${arr[0]}/${day}/${arr[2]}`;
          date = moment(text, 'MM/DD/YYYY');
          if (date.isValid()) {
            return date.format('MM/DD/YYYY');
          }
        }
      }
    }
  }
  return text;
};
