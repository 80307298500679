import { Directive, Input } from '@angular/core';

let uniqueId = 0;

/** Hint text to be shown underneath the form field control. */
@Directive({
  selector: 'ids-hint',
  host: {
    class: 'ids-adc-form-field-hint ids-adc-form-field-bottom-align',
    '[class.ids-adc-form-field-hint-end]': 'align === "end"',
    '[id]': 'id',
    // Remove align attribute to prevent it from interfering with layout.
    '[attr.align]': 'null',
  },
  standalone: true,
})
export class IdsHint {
  /** Align the hint label at the start or end of the line. */
  @Input() align: 'start' | 'end' = 'start';

  /** Unique ID for the hint. Used for the `aria-describedby` on the form field control. */
  @Input() id: string = `ids-adc-hint-${uniqueId++}`;
}
