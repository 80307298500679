import { NgClass } from '@angular/common';
import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { accordionAnimations } from '../../cdk/accordion/accordion-animations';
import { IdsIcon } from '../icon/icon.component';

type IdsAccordionExpandedState = 'expanded' | 'collapsed';

/**
 * Navigation component that works with an app router or stateful event changes from a view object model.
 */
@Component({
  selector: 'ids-nav-item',
  standalone: true,
  host: {
    class: 'ids-nav-item',
  },
  imports: [NgClass, RouterLink, RouterLinkActive, IdsIcon],
  exportAs: 'idsNavItem',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [accordionAnimations.indicatorRotate],
})
export class IdsNavItem {
  /** Unique navigation identifier. Useful for targeting a specific item. */
  @Input() id?: string;

  /** The url to navigate to if using the router. `undefined` routes remain inactive. */
  @Input() url?: string | string[];

  /**
   * Statefully set the `activated` status on a nav item.
   *
   * If used in an accordion or parent element with an `expanded` property, this must be used to detect the expanded state.
   *
   * This is necessary because:
   * 1. Nav item links with the routerLinkActive directive cannot have a dynamically set class of the same name. i.e. `active`.
   * 2. There isn't a way to detect when routerLinkActive dynamically sets the `active` class via JavaScript or `ngAfterViewInit`.
   *
   * Settings `activated` and using this stateful toggle syncs the expanded state of a parent with the routerLinkActive directive of the child nav item.
   */
  @Input({ transform: booleanAttribute })
  get activated(): boolean {
    return this._activated;
  }
  set activated(value: any) {
    this._activated = value;
  }
  private _activated = false;

  /** Set the disabled status on a nav item. */
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = value;
  }
  private _disabled = false;

  /** Makes the nav item border rounded on all corners. If false, only the right edge will be rounded. */
  @Input({ transform: booleanAttribute })
  get rounded(): boolean {
    return this._rounded;
  }
  set rounded(value: any) {
    this._rounded = value;
  }
  private _rounded = false;

  /** Hide or show the chevron icon. NOTE: Only use in accordions. */
  @Input({ transform: booleanAttribute })
  get expandable(): boolean {
    return this._expandable;
  }
  set expandable(value: any) {
    this._expandable = value;
  }
  private _expandable = false;

  /** Listen for the nav item is in expanded state. */
  @Input({ transform: booleanAttribute })
  get expanded(): boolean {
    return this._expanded;
  }
  set expanded(value: any) {
    this._expanded = value;
  }
  private _expanded = false;

  /** Emits a clicked link event. Remains inactive if `undefined`. */
  @Output() onLinkClicked = new EventEmitter<MouseEvent>();

  setNavItemState() {
    return {
      // applies the same `active` class as a route but via a stateful change.
      // must be named separately from the router link `active` class.
      activated: this.activated && !this.expandable,
      disabled: this.disabled,
      collapsible: this.expandable,
    };
  }

  /** Gets the expanded state string of the accordion. */
  getExpandedState(): IdsAccordionExpandedState {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  /** Track the click event on a nav item, and prevent events from bubbling. */
  onNavItemNavigate(event: MouseEvent) {
    this.onLinkClicked.emit(event);
  }
}
