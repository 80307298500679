import { LocalStorageService } from '@abbadox-monorepo/core-utils';
import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

/**
 * Token interceptor that applies a stored access token to each API request.
 */
export const tokenInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn) => {
  let token: string | null = null;

  inject(LocalStorageService)
    .getItem<string>('kiosk_token')
    .subscribe((t) => (token = t));

  if (token) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return next(request);
};
