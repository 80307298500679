<form class="w-full" [formGroup]="inputForm">
  <div class="md-form md-outline form-sm relative my-0 flex" [ngStyle]="setMdFormStyle()">
    <input
      mdbInput
      mdbValidate
      #abinput
      [tabIndex]="tabindex"
      [type]="type"
      [ngStyle]="setInputStyle()"
      [ngClass]="setInputClass()"
      [(ngModel)]="text"
      (focus)="onInputFocus($event)"
      (keyup.enter)="onKeyUpEnter($event)"
      (ngModelChange)="modelChanged($event, abinput)"
      [placeholder]="placeholder"
      [maxlength]="maxlength"
      class="ids-form-control !shadow-none"
      formControlName="formInput"
    />

    @if (labelText) {
      <label *ngIf="labelText" for="form2">{{ labelText }}</label>
    }

    <div (click)="onIconClick()">
      <i
        *ngIf="cssClass && cssClass !== 'far fa-calendar-alt' && cssClass !== 'far fa-clock'"
        #abicon
        [class]="cssClass"
        [ngClass]="setIconClass()"
      >
      </i>
    </div>

    @if (cssClass && cssClass === 'far fa-calendar-alt') {
      <div
        class="flex cursor-pointer"
        #abicon
        #t="ngbTooltip"
        [tabIndex]="tabindex"
        [ngbTooltip]="dateRangeTooltip"
        placement="bottom-left bottom-right"
        [autoClose]="false"
        container="body"
        triggers="manual"
        tooltipClass="appointment-tooltip date-range-tooltip"
        (click)="datePickerClick(t)"
        (keyup.enter)="datePickerClick(t)"
      >
        <i [class]="cssClass" [ngClass]="setIconClass()"> </i>
      </div>
    }

    @if (cssClass && cssClass === 'far fa-clock') {
      <div class="flex cursor-pointer" (click)="timePickerClick($event)" (keyup.enter)="timePickerClick($event)">
        <i #abicon [tabIndex]="tabindex" [class]="cssClass" [ngClass]="setIconClass()"> </i>
      </div>
    }
    <span class="unit absolute m-0 flex h-full items-center" *ngIf="unit" #abspan [ngStyle]="setUnitStyle()">
      {{ unit }}
    </span>

    @if (showErrorText && input.invalid && (input.dirty || input.touched)) {
      <span *ngIf="showErrorText && input.invalid && (input.dirty || input.touched)">{{ errorMessage }}</span>
    }
  </div>
</form>

<ng-template #dateRangeTooltip>
  <ids-date-range
    [id]="guid"
    class="absolute flex"
    [ngStyle]="setDateRangeStyle()"
    [dates]="dates"
    [isBtnToday]="true"
    [cssClass]="'light'"
    [title]="''"
    [minDate]="minDate"
    [maxDate]="maxDate"
    (dateRangePresetsChanged)="dateRangePresetsChange($event)"
    (close)="datePickerClick(null)"
  >
  </ids-date-range>
</ng-template>
