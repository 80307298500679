// Auth
export const LOCAL_STORAGE_KIOSK_TOKEN_KEY = 'kiosk_token';
export const LOCAL_STORAGE_KIOSK_PROFILE_KEY = 'kiosk_profile';

// State - Idle
export const DEFAULT_IDLE_TIEMOUT_IN_SECONDS = 60; // 1 minute

// Timer$
export const STARTING_VALUE_TO_EMIT = 1;
export const INTERVAL_TO_EMIT_IN_MILLISECONDS = 1000; // 1 second

// Toast
export const TOAST_DISMISS_DURATION_IN_MILLISECONDS = 3000; // 3 seconds
