import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  Optional,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { IdsIcon } from '../icon/icon.component';

/** Default options for `idsBadge`.  */
export interface IdsBadgeDefaultOptions {
  /** Theme color of the badge. */
  badgeColor?: string;
  /** Theme badge label's color. */
  labelColor?: string;
}

/** Injection token to be used to override the default options for `idsBadge`. */
export const IDS_BADGE_DEFAULT_OPTIONS = new InjectionToken<IdsBadgeDefaultOptions>('IDS_BADGE_DEFAULT_OPTIONS');

/** Wraps elements in a rounded background with a color. */
@Component({
  selector: 'ids-badge',
  standalone: true,
  host: {
    class: 'ids-badge',
    '[class.ids-badge-icon]': 'hasIcon',
    '[class.ids-badge-label]': '!hasIcon',
    '[style.backgroundColor]': 'badgeColor',
    '[style.color]': 'labelColor',
  },
  template: `
    @if (!hasIcon) {
      <!-- Render default text content. -->
      <span class="ids-badge-label"><ng-content></ng-content></span>
    }

    <!-- Render icons separately from other content. -->
    <ng-content select="[idsIcon]"></ng-content>
  `,
  exportAs: 'idsBadge',
  styleUrls: ['./badge.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdsBadge implements AfterContentInit {
  @ContentChildren(IdsIcon, { descendants: true }) _icon!: QueryList<IdsIcon>;

  /** The badges's color. */
  @Input()
  get badgeColor() {
    return this._badgeColor || this._defaultBadgeColor;
  }
  set badgeColor(value: string | null | undefined) {
    this._badgeColor = value;
  }
  private _badgeColor: string | null | undefined;
  private _defaultBadgeColor = '';

  /** The badges label's color. */
  @Input()
  get labelColor() {
    return this._labelColor || this._defaultLabelColor;
  }
  set labelColor(value: string | null | undefined) {
    this._labelColor = value;
  }
  private _labelColor: string | null | undefined;
  private _defaultLabelColor = '';

  hasIcon = false;

  constructor(
    public _elementRef: ElementRef,
    @Optional() @Inject(IDS_BADGE_DEFAULT_OPTIONS) defaults?: IdsBadgeDefaultOptions,
  ) {
    if (defaults) {
      if (defaults.badgeColor) {
        this.badgeColor = this._defaultBadgeColor = defaults.badgeColor;
      }

      if (defaults.labelColor) {
        this.labelColor = this._defaultLabelColor = defaults.labelColor;
      }
    }
  }

  /** Checks for instances of `ids-icon` content. Normal labels and icons shouldn't exist at the same time in a badge. */
  ngAfterContentInit(): void {
    this.hasIcon = !!this._icon.find((i) => Boolean(i.icon));
  }
}
