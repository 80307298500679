import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { dateValidator, typeDateValidate } from './validators';

export const dobFormControl = new UntypedFormControl(null, [Validators.required, dateValidator(true)]);

@Component({
  selector: 'storybook-date-picker-harness',
  template: `
    <div class="bg-bg-cool w-auto p-12">
      <span class="text-text-tertiary text-nowrap">DOB*</span>
      <ids-date-picker
        [tabindex]="10"
        [ngClass]="{ disabled }"
        [validator]="validator"
        [(text)]="dob"
        cssClass="far fa-calendar-alt"
        [placeholder]="'MM/DD/YYYY'"
        [inputFormControl]="dobFormControl"
        [showErrorText]="true"
      ></ids-date-picker>
    </div>
  `,
})
export class DatePickerHarness {
  dobFormControl = new UntypedFormControl(null, [Validators.required, dateValidator(true)]);
  validator = typeDateValidate;

  @Input() dob = '';
  @Input() disabled = false;
}
