export function getIdsFormFieldPlaceholderConflictError(): Error {
  return Error('Placeholder attribute and child element were both specified.');
}

export function getIdsFormFieldDuplicatedHintError(align: string): Error {
  return Error(`A hint was already declared for 'align="${align}"'.`);
}

export function getIdsFormFieldMissingControlError(): Error {
  return Error('ids-form-field must contain a IdsFormFieldControl.');
}
