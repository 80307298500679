import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef,
} from '@angular/core';
import { SimpleToast, TextOnlyToast } from './simple-toast.component';
import { IDS_TOAST_DATA, IdsToastConfig } from './toast-config';
import { IdsToastContainer } from './toast-container.component';
import { IdsToastRef } from './toast-ref';

export function IDS_TOAST_DEFAULT_OPTIONS_FACTORY(): IdsToastConfig {
  return new IdsToastConfig();
}

/** Injection token that can be used to specify default toast. */
export const IDS_TOAST_DEFAULT_OPTIONS = new InjectionToken<IdsToastConfig>('IdsToastDefaultOptions', {
  providedIn: 'root',
  factory: IDS_TOAST_DEFAULT_OPTIONS_FACTORY,
});

@Injectable({
  providedIn: 'root',
})
export class IdsToast implements OnDestroy {
  /**
   * Reference to the current toast in the view *at this level* (in the Angular injector tree).
   * If there is a parent toast service, all operations should delegate to that parent
   * via `_openedToastRef`.
   */
  private _toastRefAtThisLevel: IdsToastRef<any> | null = null;

  /** The component that should be rendered as the toast's simple component. */
  simpleToastComponent = SimpleToast;

  /** The container component that attaches the provided template or component. */
  toastContainerComponent = IdsToastContainer;

  /** Reference to the currently opened toast at *any* level. */
  get _openedToastRef(): IdsToastRef<any> | null {
    const parent = this._parentToast;
    return parent ? parent._openedToastRef : this._toastRefAtThisLevel;
  }

  set _openedToastRef(value: IdsToastRef<any> | null) {
    if (this._parentToast) {
      this._parentToast._openedToastRef = value;
    } else {
      this._toastRefAtThisLevel = value;
    }
  }

  constructor(
    private _overlay: Overlay,
    private _injector: Injector,
    @Optional() @SkipSelf() private _parentToast: IdsToast,
    @Inject(IDS_TOAST_DEFAULT_OPTIONS) private _defaultConfig: IdsToastConfig,
  ) {}

  /**
   * Creates and dispatches a toast with a custom component for the content, removing any
   * currently opened toasts.
   *
   * @param component Component to be instantiated.
   * @param config Extra configuration for the toast.
   */
  openFromComponent<T, D = any>(component: ComponentType<T>, config?: IdsToastConfig<D>): IdsToastRef<T> {
    return this._attach(component, config) as IdsToastRef<T>;
  }

  /**
   * Creates and dispatches a toast with a custom template for the content, removing any
   * currently opened toasts.
   *
   * @param template Template to be instantiated.
   * @param config Extra configuration for the toast.
   */
  openFromTemplate(template: TemplateRef<any>, config?: IdsToastConfig): IdsToastRef<EmbeddedViewRef<any>> {
    return this._attach(template, config);
  }

  /**
   * Opens a toast with a message and an optional action.
   * @param message The message to show in the toast.
   * @param action The label for the toast action.
   * @param config Additional configuration options for the toast.
   */
  open(message: string, action: string = '', config?: IdsToastConfig): IdsToastRef<TextOnlyToast> {
    const _config = { ...this._defaultConfig, ...config };

    // Since the user doesn't have access to the component, we can
    // override the data to pass in our own message and action.
    _config.data = { message, action };

    return this.openFromComponent(this.simpleToastComponent, _config);
  }

  /**
   * Dismisses the currently-visible toast.
   */
  dismiss(): void {
    if (this._openedToastRef) {
      this._openedToastRef.dismiss();
    }
  }

  ngOnDestroy() {
    // Only dismiss the toast at the current level on destroy.
    if (this._toastRefAtThisLevel) {
      this._toastRefAtThisLevel.dismiss();
    }
  }

  /**
   * Attaches the toast container component to the overlay.
   */
  private _attachToastContainer(overlayRef: OverlayRef, config: IdsToastConfig): IdsToastContainer {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = Injector.create({
      parent: userInjector || this._injector,
      providers: [{ provide: IdsToastConfig, useValue: config }],
    });

    const containerPortal = new ComponentPortal(this.toastContainerComponent, config.viewContainerRef, injector);
    const containerRef: ComponentRef<IdsToastContainer> = overlayRef.attach(containerPortal);
    containerRef.instance.toastConfig = config;
    return containerRef.instance;
  }

  /**
   * Places a new component or a template as the content of the toast container.
   */
  private _attach<T>(
    content: ComponentType<T> | TemplateRef<T>,
    userConfig?: IdsToastConfig,
  ): IdsToastRef<T | EmbeddedViewRef<any>> {
    const config = { ...new IdsToastConfig(), ...this._defaultConfig, ...userConfig };
    const overlayRef = this._createOverlay(config);
    const container = this._attachToastContainer(overlayRef, config);
    const toastRef = new IdsToastRef<T | EmbeddedViewRef<any>>(container, overlayRef);

    if (content instanceof TemplateRef) {
      const portal = new TemplatePortal(content, null!, {
        $implicit: config.data,
        toastRef,
      } as any);

      toastRef.instance = container.attachTemplatePortal(portal);
    } else {
      const injector = this._createInjector(config, toastRef);
      const portal = new ComponentPortal(content, undefined, injector);
      const contentRef = container.attachComponentPortal<T>(portal);

      // We can't pass this via the injector, because the injector is created earlier.
      toastRef.instance = contentRef.instance;
    }

    this._animateToast(toastRef, config);
    this._openedToastRef = toastRef;
    return this._openedToastRef;
  }

  /** Animates the old toast out and the new one in. */
  private _animateToast(toastRef: IdsToastRef<any>, config: IdsToastConfig) {
    // When the toast is dismissed, clear the reference to it.
    toastRef.afterDismissed().subscribe(() => {
      // Clear the toast ref if it hasn't already been replaced by a newer toast.
      if (this._openedToastRef == toastRef) {
        this._openedToastRef = null;
      }
    });

    if (this._openedToastRef) {
      // If a toast is already in view, dismiss it and enter the
      // new toast after exit animation is complete.
      this._openedToastRef.afterDismissed().subscribe(() => {
        toastRef.containerInstance.enter();
      });
      this._openedToastRef.dismiss();
    } else {
      // If no toast is in view, enter the new toast.
      toastRef.containerInstance.enter();
    }

    // If a dismiss timeout is provided, set up dismiss based on after the toast is opened.
    if (config.duration && config.duration > 0) {
      toastRef.afterOpened().subscribe(() => toastRef._dismissAfter(config.duration!));
    }
  }

  /**
   * Creates a new overlay and places it in the correct location.
   * @param config The user-specified toast config.
   */
  private _createOverlay(config: IdsToastConfig): OverlayRef {
    const overlayConfig = new OverlayConfig();
    overlayConfig.direction = config.direction;

    let positionStrategy = this._overlay.position().global();
    // Set horizontal position.
    const isRtl = config.direction === 'rtl';
    const isLeft =
      config.horizontalPosition === 'left' ||
      (config.horizontalPosition === 'start' && !isRtl) ||
      (config.horizontalPosition === 'end' && isRtl);
    const isRight = !isLeft && config.horizontalPosition !== 'center';
    if (isLeft) {
      positionStrategy.left('0');
    } else if (isRight) {
      positionStrategy.right('0');
    } else {
      positionStrategy.centerHorizontally();
    }
    // Set horizontal position.
    if (config.verticalPosition === 'top') {
      positionStrategy.top('0');
    } else {
      positionStrategy.bottom('0');
    }

    overlayConfig.positionStrategy = positionStrategy;
    return this._overlay.create(overlayConfig);
  }

  /**
   * Creates an injector to be used inside of a toast component.
   * @param config Config that was used to create the toast.
   * @param toastRef Reference to the toast.
   */
  private _createInjector<T>(config: IdsToastConfig, toastRef: IdsToastRef<T>): Injector {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;

    return Injector.create({
      parent: userInjector || this._injector,
      providers: [
        { provide: IdsToastRef, useValue: toastRef },
        { provide: IDS_TOAST_DATA, useValue: config.data },
      ],
    });
  }
}
