import { Directive, ElementRef, Inject, Input, booleanAttribute, numberAttribute } from '@angular/core';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { IDS_CHIP } from './chip-tokens';

/** Section within a chip. */
@Directive({
  selector: '[idsChipAction]',
  host: {
    class: 'adc-evolution-chip__action ids-adc-chip-action',
    '[class.adc-evolution-chip__action--primary]': '_isPrimary',
    '[class.adc-evolution-chip__action--presentational]': '!isInteractive',
    '[class.adc-evolution-chip__action--trailing]': '!_isPrimary',
    '[attr.tabindex]': '_getTabindex()',
    '[attr.disabled]': '_getDisabledAttribute()',
    '[attr.aria-disabled]': 'disabled',
    '(click)': '_handleClick($event)',
    '(keydown)': '_handleKeydown($event)',
  },
  standalone: true,
})
export class IdsChipAction {
  /** Whether the action is interactive. */
  @Input() isInteractive = true;

  /** Whether this is the primary action in the chip. */
  _isPrimary = true;

  /** Whether the action is disabled. */
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled || this._parentChip.disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }
  private _disabled = false;

  /** Tab index of the action. */
  @Input({
    transform: (value: unknown) => (value == null ? -1 : numberAttribute(value)),
  })
  tabIndex: number = -1;

  /**
   * Private API to allow focusing this chip when it is disabled.
   */
  @Input()
  private _allowFocusWhenDisabled = false;

  /**
   * Determine the value of the disabled attribute for this chip action.
   */
  protected _getDisabledAttribute(): string | null {
    // When this chip action is disabled and focusing disabled chips is not permitted, return empty
    // string to indicate that disabled attribute should be included.
    return this.disabled && !this._allowFocusWhenDisabled ? '' : null;
  }

  /**
   * Determine the value of the tabindex attribute for this chip action.
   */
  protected _getTabindex(): string | null {
    return (this.disabled && !this._allowFocusWhenDisabled) || !this.isInteractive ? null : this.tabIndex.toString();
  }

  constructor(
    public _elementRef: ElementRef<HTMLElement>,
    @Inject(IDS_CHIP)
    protected _parentChip: {
      _handlePrimaryActionInteraction(): void;
      remove(): void;
      disabled: boolean;
      _isEditing?: boolean;
    },
  ) {
    if (_elementRef.nativeElement.nodeName === 'BUTTON') {
      _elementRef.nativeElement.setAttribute('type', 'button');
    }
  }

  focus() {
    this._elementRef.nativeElement.focus();
  }

  _handleClick(event: MouseEvent) {
    if (!this.disabled && this.isInteractive && this._isPrimary) {
      event.preventDefault();
      this._parentChip._handlePrimaryActionInteraction();
    }
  }

  _handleKeydown(event: KeyboardEvent) {
    if (
      (event.keyCode === ENTER || event.keyCode === SPACE) &&
      !this.disabled &&
      this.isInteractive &&
      this._isPrimary &&
      !this._parentChip._isEditing
    ) {
      event.preventDefault();
      this._parentChip._handlePrimaryActionInteraction();
    }
  }
}
