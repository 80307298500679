import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { IconNames, IconsMap } from './icon-map';

/** Default options for `idsIcon`.  */
export interface IdsIconDefaultOptions {
  /** Theme color of the icon. */
  color?: string;
  /** Set the width of icons to be consist. */
  fixedWidth?: boolean;
}

/** Injection token to be used to override the default options for `idsIcon`. */
export const IDS_ICON_DEFAULT_OPTIONS = new InjectionToken<IdsIconDefaultOptions>('IDS_ICON_DEFAULT_OPTIONS');

/**
 * An icon component using fontawesome icons. Sets up icons via
 * package manager to keep icons updated rather than management
 * by css imports.
 */
@Component({
  selector: '[idsIcon]',
  standalone: true,
  host: {
    '[style.color]': 'color',
    '[class.fa-fw]': 'fixedWidth',
    '[class.ids-icon]': 'true',
    '[attr.aria-hidden]': 'true',
  },
  exportAs: 'idsIcon',
  template: '',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IdsIcon {
  /** The icon's color. */
  @Input()
  get color() {
    return this._color || this._defaultColor;
  }
  set color(value: string | null | undefined) {
    this._color = value;
  }
  private _color: string | null | undefined;
  private _defaultColor = '';

  /** Name of the icon in the SVG icon set.  If using the Font Awesome script <head>, icon's are swapped for their SVG version. */
  @Input()
  get icon(): IconNames | string {
    return this._icon;
  }
  set icon(value: IconNames | string) {
    this._icon = value;
    this.updateFontIcon(value);
  }
  private _icon: IconNames | string = '';

  /** Use fixed widths in icons. */
  @Input({ transform: booleanAttribute })
  get fixedWidth(): boolean {
    return this._fixedWidth;
  }
  set fixedWidth(value: boolean) {
    this._fixedWidth = value;
  }
  private _fixedWidth = false;

  constructor(
    public _elementRef: ElementRef,
    @Optional() @Inject(IDS_ICON_DEFAULT_OPTIONS) defaults?: IdsIconDefaultOptions,
  ) {
    if (defaults) {
      if (defaults.color) {
        this.color = this._defaultColor = defaults.color;
      }

      if (defaults.fixedWidth) {
        this.fixedWidth = this._fixedWidth = defaults.fixedWidth;
      }
    }
  }

  /**
   * Check the the icon name is registered.
   *
   * @param icon - icon as a name or classname string to check
   * @returns - boolean if icon is registered
   */
  iconType(icon: any): icon is IconNames {
    return IconsMap.has(icon);
  }

  parseIconClass(iconClass: string): string[] {
    return /\s/g.test(iconClass) ? iconClass.split(' ') : [iconClass];
  }

  /**
   * Gets the icon from the icon registry by name or renders the icon classname.
   */
  private updateFontIcon(iconName: IconNames | string) {
    const elem: HTMLElement = this._elementRef.nativeElement;
    const icon = this.iconType(iconName) ? IconsMap.get(iconName) : this.parseIconClass(iconName);

    if (icon) {
      elem.classList.add(...icon);
    }
  }
}
