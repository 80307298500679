import { ApiHttpService } from '@abbadox-monorepo/core-http-client';
import {
  AuthTokenResponse,
  LoginUserDto,
  LoginUserDto as LoginUserRequest,
  Profile,
} from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Authentication service handling login.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthHttpClientService {
  private readonly apiHttpService = inject(ApiHttpService);

  login(credentials: LoginUserDto): Observable<AuthTokenResponse> {
    return this.apiHttpService.post<AuthTokenResponse, LoginUserRequest>('/auth/login', credentials);
  }

  profile(): Observable<Profile> {
    return this.apiHttpService.get<Profile>('/users/profile');
  }
}
