import { Directive, InjectionToken, Input } from '@angular/core';

/**
 * Injection token that can be used to reference instances of `IdsPrefix`. It serves as
 * alternative token to the actual `IdsPrefix` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const IDS_PREFIX = new InjectionToken<IdsPrefix>('IdsPrefix');

/** Prefix to be placed in front of the form field. */
@Directive({
  selector: '[idsPrefix], [idsIconPrefix], [idsTextPrefix]',
  providers: [{ provide: IDS_PREFIX, useExisting: IdsPrefix }],
  standalone: true,
})
export class IdsPrefix {
  @Input('idsTextPrefix')
  set _isTextSelector(value: '') {
    this._isText = true;
  }

  _isText = false;
}
