import { AuthTokenResponse, Profile } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

export const AuthApiActions = createActionGroup({
  source: 'Auth API',
  events: {
    login: props<{ username: string; password: string }>(),
    loginSuccess: props<AuthTokenResponse>(),
    loginFailed: props<{ error: any }>(),
    loadProfileAttempted: emptyProps(),
    loadProfileSuccess: props<{ profile: Profile }>(),
    loadProfileFailed: props<{ error: any }>(),
  },
});

export type UserState = Profile & { access_token: string };

export const initialUserState: UserState = {
  accountConfigId: 0,
  accountName: '',
  username: '',
  logo: null,
  deviceUID: null,
  kioskConfigurationId: 0,
  isActive: false,
  isDeviceLock: null,
  idleTimeoutInSeconds: 0,
  sessionTimeoutInSeconds: 0,
  authRetrySessionTimeoutInSeconds: 0,
  numberOfAuthRetryAttempts: 0,
  appointmentStatusChangeUponCompletion: 0,
  deviceLocations: [],
  access_token: '',
};

export interface AuthState {
  user: UserState;
  authenticated: boolean;
  profileLoaded: boolean;
}

export const initialAuthState: AuthState = {
  user: initialUserState,
  authenticated: false,
  profileLoaded: false,
};

export const authFeature = createFeature({
  name: 'auth',
  reducer: createReducer(
    initialAuthState,
    on(AuthApiActions.login, (state) => ({
      ...state,
      authenticated: false,
    })),
    on(AuthApiActions.loginSuccess, (state, { access_token }) => ({
      ...state,
      user: {
        ...state.user,
        access_token,
      },
      authenticated: Boolean(access_token),
    })),
    on(AuthApiActions.loadProfileSuccess, (state, { profile }) => ({
      ...state,
      user: {
        ...state.user,
        ...profile,
      },
      profileLoaded: true,
    })),
  ),
  extraSelectors: ({ selectUser }) => ({
    selectAccountName: createSelector(selectUser, (profile) => profile.accountName),
    selectProfile: createSelector(selectUser, ({ access_token, ...profile }) => profile),
    selectLogo: createSelector(selectUser, (user) => user?.logo),
    selectIdleTimeoutInSeconds: createSelector(selectUser, (user) => user?.idleTimeoutInSeconds),
    selectSessionTimeoutInSeconds: createSelector(selectUser, (user) => user?.sessionTimeoutInSeconds),
    selectAppointmentStatusChangeUponCompletion: createSelector(
      selectUser,
      (user) => user?.appointmentStatusChangeUponCompletion,
    ),
  }),
});

export const {
  name: AUTH_STATE_FEATURE_KEY,
  reducer: authReducer,
  selectAuthenticated,
  selectProfile,
  selectProfileLoaded,
  selectAccountName,
  selectLogo,
  selectUser,
  selectIdleTimeoutInSeconds,
  selectSessionTimeoutInSeconds,
  selectAppointmentStatusChangeUponCompletion,
} = authFeature;

export const selectAuthRetrySessionTimeoutInSeconds = createSelector(
  selectProfile,
  (profile) => profile.authRetrySessionTimeoutInSeconds,
);

export const selectNumberOfAuthRetryAttempts = createSelector(
  selectProfile,
  (profile) => profile.numberOfAuthRetryAttempts,
);

export const selectDeviceLocations = createSelector(selectProfile, (profile) => profile.deviceLocations);
