import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { Directive } from '@angular/core';
import { IdsChipAction } from './chip-action.directive';
import { IDS_CHIP_AVATAR, IDS_CHIP_REMOVE, IDS_CHIP_TRAILING_ICON } from './chip-tokens';

/** Avatar image within a chip. */
@Directive({
  selector: 'ids-chip-avatar, [idsChipAvatar]',
  host: {
    class: 'ids-adc-chip-avatar adc-evolution-chip__icon adc-evolution-chip__icon--primary',
    role: 'img',
  },
  providers: [{ provide: IDS_CHIP_AVATAR, useExisting: IdsChipAvatar }],
  standalone: true,
})
export class IdsChipAvatar {}

/** Non-interactive trailing icon in a chip. */
@Directive({
  selector: 'ids-chip-trailing-icon, [idsChipTrailingIcon]',
  host: {
    class: 'ids-adc-chip-trailing-icon adc-evolution-chip__icon adc-evolution-chip__icon--trailing',
    'aria-hidden': 'true',
  },
  providers: [{ provide: IDS_CHIP_TRAILING_ICON, useExisting: IdsChipTrailingIcon }],
  standalone: true,
})
export class IdsChipTrailingIcon extends IdsChipAction {
  /** Support non-interactive trailing icons. */
  override isInteractive = false;

  override _isPrimary = false;
}

/**
 * Directive to remove the parent chip when the trailing icon is clicked or
 * when the ENTER key is pressed on it.
 *
 * Recommended for use with the "close" icon.
 *
 * Example:
 *
 * ```
 * <ids-chip>
 *   <ids-icon idsChipRemove icon="close"></ids-icon>
 * </ids-chip>
 * ```
 */

@Directive({
  selector: '[idsChipRemove]',
  host: {
    class:
      'ids-adc-chip-remove ids-adc-chip-trailing-icon ids-adc-focus-indicator ' +
      'adc-evolution-chip__icon adc-evolution-chip__icon--trailing',
    role: 'button',
    '[attr.aria-hidden]': 'null',
  },
  providers: [{ provide: IDS_CHIP_REMOVE, useExisting: IdsChipRemove }],
  standalone: true,
})
export class IdsChipRemove extends IdsChipAction {
  override _isPrimary = false;

  override _handleClick(event: MouseEvent): void {
    if (!this.disabled) {
      event.stopPropagation();
      event.preventDefault();
      this._parentChip.remove();
    }
  }

  override _handleKeydown(event: KeyboardEvent) {
    if ((event.keyCode === ENTER || event.keyCode === SPACE) && !this.disabled) {
      event.stopPropagation();
      event.preventDefault();
      this._parentChip.remove();
    }
  }
}
