<span class="ids-adc-chip-focus-overlay"></span>

<span class="adc-evolution-chip__cell adc-evolution-chip__cell--primary">
  <span idsChipAction [isInteractive]="false">
    @if (leadingIcon) {
      <span class="adc-evolution-chip__graphic ids-adc-chip-graphic">
        <ng-content select="ids-chip-avatar, [matChipAvatar]"></ng-content>
      </span>
    }
    <span class="adc-evolution-chip__text-label ids-adc-chip-action-label">
      <ng-content></ng-content>
      <span class="ids-adc-chip-primary-focus-indicator ids-adc-focus-indicator"></span>
    </span>
  </span>
</span>

@if (_hasTrailingIcon()) {
  <span class="adc-evolution-chip__cell adc-evolution-chip__cell--trailing">
    <ng-content select="ids-chip-trailing-icon,[matChipRemove],[matChipTrailingIcon]"></ng-content>
  </span>
}
