import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgTemplateOutlet } from '@angular/common';
import {
  ANIMATION_MODULE_TYPE,
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  QueryList,
  ViewChild,
  ViewEncapsulation,
  isDevMode,
} from '@angular/core';
import { AbstractControlDirective } from '@angular/forms';
import { Subject, merge, takeUntil } from 'rxjs';
import { IDS_ERROR, IdsError } from './error.directive';
import { IdsFormFieldControl } from './form-field-control.directive';
import { getIdsFormFieldDuplicatedHintError, getIdsFormFieldMissingControlError } from './form-field-errors';
import { IdsHint } from './hint.directive';
import { IDS_PREFIX, IdsPrefix } from './prefix.directive';
import { IDS_SUFFIX, IdsSuffix } from './suffix.directive';

/** Behaviors for how the subscript height is set. */
export type SubscriptSizing = 'fixed' | 'dynamic';

/**
 * Represents the default options for the form field that can be configured
 * using the `MAT_FORM_FIELD_DEFAULT_OPTIONS` injection token.
 */
export interface IdsFormFieldDefaultOptions {
  /** Toggle required marker default visibility. */
  hideRequiredMarker?: boolean;
  /** Default configuration to reserve space for one line. */
  subscriptSizing?: SubscriptSizing;
}

/**
 * Injection token that can be used to inject an instances of `IdsFormField`. It serves
 * as alternative token to the actual `IdsFormField` class which would cause unnecessary
 * retention of the `IdsFormField` class and its component metadata.
 */
export const IDS_FORM_FIELD = new InjectionToken<IdsFormFieldComponent>('IdsFormField');

/**
 * Injection token that can be used to configure the
 * default options for all form field within an app.
 */
export const MAT_FORM_FIELD_DEFAULT_OPTIONS = new InjectionToken<IdsFormFieldDefaultOptions>(
  'MAT_FORM_FIELD_DEFAULT_OPTIONS',
);

/** The unique id of the input for tracking and preventing collisions. */
let uniqueId = 0;

/** Default way that the subscript element height is set. */
const DEFAULT_SUBSCRIPT_SIZING: SubscriptSizing = 'fixed';

export const IDS_FORM_FIELD_HOST = {
  class: 'ids-adc-form-field',
  '[class.ids-adc-form-field-has-icon-prefix]': '_hasIconPrefix',
  '[class.ids-adc-form-field-has-icon-suffix]': '_hasIconSuffix',
  '[class.ids-form-field-invalid]': '_control.errorState',
  '[class.ids-form-field-disabled]': '_control.disabled',
  '[class.ids-form-field-autofilled]': '_control.autofilled',
  '[class.ids-focused]': '_control.focused',
  '[class.ng-untouched]': '_shouldForward("untouched")',
  '[class.ng-touched]': '_shouldForward("touched")',
  '[class.ng-pristine]': '_shouldForward("pristine")',
  '[class.ng-dirty]': '_shouldForward("dirty")',
  '[class.ng-valid]': '_shouldForward("valid")',
  '[class.ng-invalid]': '_shouldForward("invalid")',
  '[class.ng-pending]': '_shouldForward("pending")',
};

@Component({
  selector: 'ids-form-field',
  standalone: true,
  host: IDS_FORM_FIELD_HOST,
  exportAs: 'idsFormField',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  providers: [{ provide: IDS_FORM_FIELD, useExisting: IdsFormFieldComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [NgTemplateOutlet, IdsHint],
})
export class IdsFormFieldComponent implements AfterContentInit, AfterContentChecked, AfterViewInit, OnDestroy {
  @ViewChild('textField') _textField!: ElementRef<HTMLElement>;
  @ViewChild('iconPrefixContainer') _iconPrefixContainer!: ElementRef<HTMLElement>;
  @ViewChild('textPrefixContainer') _textPrefixContainer!: ElementRef<HTMLElement>;

  @ContentChild(IdsFormFieldControl) _formFieldControl!: IdsFormFieldControl<any>;
  @ContentChildren(IDS_PREFIX, { descendants: true }) _prefixChildren!: QueryList<IdsPrefix>;
  @ContentChildren(IDS_SUFFIX, { descendants: true }) _suffixChildren!: QueryList<IdsSuffix>;
  @ContentChildren(IDS_ERROR, { descendants: true }) _errorChildren!: QueryList<IdsError>;
  @ContentChildren(IdsHint, { descendants: true }) _hintChildren!: QueryList<IdsHint>;

  /** Whether the required marker should be hidden. */
  @Input()
  get hideRequiredMarker(): boolean {
    return this._hideRequiredMarker;
  }
  set hideRequiredMarker(value: BooleanInput) {
    this._hideRequiredMarker = coerceBooleanProperty(value);
  }
  private _hideRequiredMarker = false;

  /**
   * Whether the form field should reserve space for one line of hint/error text (default)
   * or to have the spacing grow from 0px as needed based on the size of the hint/error content.
   * Note that when using dynamic sizing, layout shifts will occur when hint/error text changes.
   */
  @Input()
  get subscriptSizing(): SubscriptSizing {
    return this._subscriptSizing || this._defaults?.subscriptSizing || DEFAULT_SUBSCRIPT_SIZING;
  }
  set subscriptSizing(value: SubscriptSizing) {
    this._subscriptSizing = value || this._defaults?.subscriptSizing || DEFAULT_SUBSCRIPT_SIZING;
  }
  private _subscriptSizing: SubscriptSizing | null = null;

  /** Text for the form field hint. */
  @Input()
  get hintLabel(): string {
    return this._hintLabel;
  }
  set hintLabel(value: string) {
    this._hintLabel = value;
    this._processHints();
  }
  private _hintLabel = '';

  _hasIconPrefix = false;
  _hasTextPrefix = false;
  _hasIconSuffix = false;
  _hasTextSuffix = false;

  // Unique id for the internal form field label.
  readonly _labelId = `ids-adc-form-field-label-${uniqueId++}`;

  // Unique id for the hint label.
  readonly _hintLabelId = `ids-adc-hint-${uniqueId++}`;

  /** Gets the current form field control */
  get _control(): IdsFormFieldControl<any> {
    return this._explicitFormFieldControl || this._formFieldControl;
  }
  set _control(value) {
    this._explicitFormFieldControl = value;
  }

  private _destroyed = new Subject<void>();
  private _isFocused: boolean | null = null;
  private _explicitFormFieldControl!: IdsFormFieldControl<any>;

  constructor(
    public _elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) private _defaults?: IdsFormFieldDefaultOptions,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) public _animationMode?: string,
  ) {
    if (_defaults) {
      this._hideRequiredMarker = Boolean(_defaults?.hideRequiredMarker);
    }
  }

  ngAfterViewInit() {
    // Initial focus state sync. This happens rarely, but we want to account for
    // it in case the form field control has "focused" set to true on init.
    this._updateFocusState();
    // Because the above changes a value used in the template after it was checked, we need
    // to trigger CD or the change might not be reflected if there is no other CD scheduled.
    this._changeDetectorRef.detectChanges();
  }

  ngAfterContentInit() {
    this._assertFormFieldControl();
    this._initializeControl();
    this._initializeSubscript();
    this._initializePrefixAndSuffix();
  }

  ngAfterContentChecked() {
    this._assertFormFieldControl();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  /**
   * Gets an ElementRef for the element that a overlay attached to the form field
   * should be positioned relative to.
   */
  getConnectedOverlayOrigin(): ElementRef {
    return this._textField || this._elementRef;
  }

  /** Initializes the registered form field control. */
  private _initializeControl() {
    const control = this._control;

    if (control.controlType) {
      this._elementRef.nativeElement.classList.add(`ids-adc-form-field-type-${control.controlType}`);
    }

    // Subscribe to changes in the child control state in order to update the form field UI.
    control.stateChanges.subscribe(() => {
      this._updateFocusState();
      this._syncDescribedByIds();
      this._changeDetectorRef.markForCheck();
    });

    // Run change detection if the value changes.
    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => this._changeDetectorRef.markForCheck());
    }
  }

  private _checkPrefixAndSuffixTypes() {
    this._hasIconPrefix = !!this._prefixChildren.find((p) => !p._isText);
    this._hasTextPrefix = !!this._prefixChildren.find((p) => p._isText);
    this._hasIconSuffix = !!this._suffixChildren.find((s) => !s._isText);
    this._hasTextSuffix = !!this._suffixChildren.find((s) => s._isText);
  }

  /** Initializes the prefix and suffix containers. */
  private _initializePrefixAndSuffix() {
    this._checkPrefixAndSuffixTypes();
    // Mark the form field as dirty whenever the prefix or suffix children change. This
    // is necessary because we conditionally display the prefix/suffix containers based
    // on whether there is projected content.
    merge(this._prefixChildren.changes, this._suffixChildren.changes).subscribe(() => {
      this._checkPrefixAndSuffixTypes();
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * Initializes the subscript by validating hints and synchronizing "aria-describedby" ids
   * with the custom form field control. Also subscribes to hint and error changes in order
   * to be able to validate and synchronize ids on change.
   */
  private _initializeSubscript() {
    // Re-validate when the number of hints changes.
    this._hintChildren.changes.subscribe(() => {
      this._processHints();
      this._changeDetectorRef.markForCheck();
    });

    // Update the aria-described by when the number of errors changes.
    this._errorChildren.changes.subscribe(() => {
      this._syncDescribedByIds();
      this._changeDetectorRef.markForCheck();
    });

    // Initial ids-hint validation and subscript describedByIds sync.
    this._validateHints();
    this._syncDescribedByIds();
  }

  /** Throws an error if the form field's control is missing. */
  private _assertFormFieldControl() {
    if (!this._control && isDevMode()) {
      throw getIdsFormFieldMissingControlError();
    }
  }

  private _updateFocusState() {
    // Handle the focus by checking if the abstract form field control focused state changes.
    if (this._control.focused && !this._isFocused) {
      this._isFocused = true;
    } else if (!this._control.focused && (this._isFocused || this._isFocused === null)) {
      this._isFocused = false;
    }

    this._textField?.nativeElement.classList.toggle('adc-text-field--focused', this._control.focused);
  }

  /**
   * Determines whether a class from the AbstractControlDirective
   * should be forwarded to the host element.
   */
  _shouldForward(prop: keyof AbstractControlDirective): boolean {
    const control = this._control ? this._control.ngControl : null;
    return control && control[prop];
  }

  /** Determines whether to display hints or errors. */
  _getDisplayedMessages(): 'error' | 'hint' {
    return this._errorChildren && this._errorChildren.length > 0 && this._control.errorState ? 'error' : 'hint';
  }

  /** Does any extra processing that is required when handling the hints. */
  private _processHints() {
    this._validateHints();
    this._syncDescribedByIds();
  }

  /**
   * Ensure that there is a maximum of one of each "ids-hint" alignment specified. The hint
   * label specified set through the input is being considered as "start" aligned.
   *
   * This method is a noop if Angular runs in production mode.
   */
  private _validateHints() {
    if (this._hintChildren && isDevMode()) {
      let startHint: IdsHint;
      let endHint: IdsHint;
      this._hintChildren.forEach((hint: IdsHint) => {
        if (hint.align === 'start') {
          if (startHint || this.hintLabel) {
            throw getIdsFormFieldDuplicatedHintError('start');
          }
          startHint = hint;
        } else if (hint.align === 'end') {
          if (endHint) {
            throw getIdsFormFieldDuplicatedHintError('end');
          }
          endHint = hint;
        }
      });
    }
  }

  /**
   * Sets the list of element IDs that describe the child control. This allows the control to update
   * its `aria-describedby` attribute accordingly.
   */
  private _syncDescribedByIds() {
    if (this._control) {
      let ids: string[] = [];

      if (this._control.userAriaDescribedBy && typeof this._control.userAriaDescribedBy === 'string') {
        ids.push(...this._control.userAriaDescribedBy.split(' '));
      }

      if (this._getDisplayedMessages() === 'hint') {
        const startHint = this._hintChildren ? this._hintChildren.find((hint) => hint.align === 'start') : null;
        const endHint = this._hintChildren ? this._hintChildren.find((hint) => hint.align === 'end') : null;

        if (startHint) {
          ids.push(startHint.id);
        } else if (this._hintLabel) {
          ids.push(this._hintLabelId);
        }

        if (endHint) {
          ids.push(endHint.id);
        }
      } else if (this._errorChildren) {
        ids.push(...this._errorChildren.map((error) => error.id));
      }

      this._control.setDescribedByIds(ids);
    }
  }
}
