export enum DateRangePresets {
  Custom,
  Today,
  Tomorrow,
  CurrentCalendarWeek,
  CurrentCalendarMonth,
  LastCalendarWeek,
  LastCalendarMonth,
  NextCalendarWeek,
  NextCalendarMonth,
  Yesterday = 100,
  Previous,
  Upcoming,
  DateRange,
  All,
}

export interface DateRangeModel {
  dateRangePresets: DateRangePresets;
  selectedNumberOf: string;
  selectedPeriodType: DropdownItem;
  strDates: string[];
  stringValue: string;
}

export interface DropdownItem {
  id: string;
  name: string;
  abbreviation: string;
  rgba: RGBA;
  accEnumId: number;
  description: string;
  groupName?: string;
}

export interface RGBA {
  R: number;
  G: number;
  B: number;
  Alfa: number;
}
