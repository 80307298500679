import { DEFAULT_IDLE_TIEMOUT_IN_SECONDS } from '@abbadox-monorepo/kiosk-core-constants';
import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from '@ngrx/store';

export const IdleActions = createActionGroup({
  source: 'Idle',
  events: {
    setIdleTimeout: props<{ idleTimeoutSeconds?: number }>(),
    setIdleState: props<{ idleState?: IDLE_STATES }>(),
    startIdleWatch: emptyProps(),
    stopIdleWatch: emptyProps(),
    triggerTimeoutDialogOpen: emptyProps(),
    triggerTimeoutDialogCloseFromTimeoutEvent: emptyProps(),
    triggerTimeoutDialogCloseFromIcon: emptyProps(),
    triggerTimeoutDialogCloseFromButton: emptyProps(),
  },
});

export enum IDLE_STATES {
  NOT_STARTED = 'NOT_STARTED',
  NOT_IDLE = 'NOT_IDLE',
  IDLE = 'IDLE',
  TIMED_OUT = 'TIMED_OUT',
}

export interface IdleState {
  idleTimeoutSeconds: number;
  idleState: IDLE_STATES;
  lastPing: Date | null;
}

export const initialIdleState: IdleState = {
  idleTimeoutSeconds: DEFAULT_IDLE_TIEMOUT_IN_SECONDS,
  idleState: IDLE_STATES.NOT_STARTED,
  lastPing: null,
};

export const idleFeature = createFeature({
  name: 'idle',
  reducer: createReducer(
    initialIdleState,
    on(IdleActions.setIdleTimeout, IdleActions.setIdleState, (state, { type, ...params }) => ({
      ...state,
      ...params,
    })),
  ),
});

export const {
  name: IDLE_STATE_FEATURE_KEY,
  reducer: idleReducer,
  selectIdleTimeoutSeconds,
  selectIdleState,
  selectLastPing,
} = idleFeature;
