import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ids-radial-progress-stepper',
  standalone: true,
  template: `
    <div class="flex w-[3.75rem]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 36 36"
        class="relative block max-h-60 overflow-hidden align-middle"
        width="100%"
        height="100%"
      >
        <path
          class="fill-none stroke-[#CFE0F8] stroke-[3.8]"
          d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="stroke-linecap fill-none stroke-[#186ADE] stroke-[2.8]"
          attr.stroke-dasharray="{{ progress }}, 100"
          d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="21.35" class="text-anchor-center fill-black text-[9.6px] font-bold">{{ progress }}%</text>
      </svg>
    </div>
  `,
  styleUrls: ['./radial-progress-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadialProgressStepperComponent {
  /** Percent complete represented by a number. */
  @Input() progress = 0;
}
