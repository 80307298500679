import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IdsButton } from '../button/button.component';
import { IdsDatePicker } from './date-picker.component';
import { IdsDateRange } from './date-range.component';
import { DatePickerHarness } from './date-picker-harness.component';

/**
 * This is a temporary module to declare legacy 3rd-party libraries.
 */
@NgModule({
  declarations: [IdsDatePicker, IdsDateRange, DatePickerHarness],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule, BsDatepickerModule.forRoot(), IdsButton],
  exports: [ReactiveFormsModule, IdsDatePicker, IdsDateRange, DatePickerHarness],
})
export class DatePickerModule {}
