<label class="ids-adc-label" [id]="_labelId" [attr.for]="_control.disableAutomaticLabeling ? null : _control.id">
  <ng-content select="ids-label"></ng-content>
  <!-- We set the required marker as a separate element, in order to make it easier to target if
    apps want to override it and to be able to set `aria-hidden` so that screen readers don't
    pick it up. -->
  @if (!hideRequiredMarker && _control.required) {
    <span aria-hidden="true" class="ids-adc-form-field-required-marker"></span>
  }
</label>

<div
  class="ids-adc-text-field-wrapper adc-text-field"
  #textField
  [class.adc-text-field--disabled]="_control.disabled"
  [class.adc-text-field--invalid]="_control.errorState"
  (click)="_control.onContainerClick($event)"
>
  <div class="ids-adc-form-field-flex">
    @if (_hasIconPrefix) {
      <div class="ids-adc-form-field-icon-prefix" #iconPrefixContainer>
        <ng-content select="[idsPrefix], [idsIconPrefix]"></ng-content>
      </div>
    }

    @if (_hasTextPrefix) {
      <div class="ids-adc-form-field-text-prefix" #textPrefixContainer>
        <ng-content select="[idsTextPrefix]"></ng-content>
      </div>
    }

    <div class="ids-adc-form-field-infix">
      <ng-content></ng-content>
    </div>

    @if (_hasTextSuffix) {
      <div class="ids-adc-form-field-text-suffix">
        <ng-content select="[idsTextSuffix]"></ng-content>
      </div>
    }

    @if (_hasIconSuffix) {
      <div class="ids-adc-form-field-icon-suffix">
        <ng-content select="[idsSuffix], [idsIconSuffix]"></ng-content>
      </div>
    }
  </div>
</div>

<div
  class="ids-adc-form-field-subscript-wrapper ids-adc-form-field-bottom-align"
  [class.ids-adc-form-field-subscript-dynamic-size]="subscriptSizing === 'dynamic'"
>
  @switch (_getDisplayedMessages()) {
    @case ('error') {
      <div class="ids-adc-form-field-error-wrapper">
        <ng-content select="ids-error, [idsError]"></ng-content>
      </div>
    }

    @case ('hint') {
      <div class="ids-adc-form-field-hint-wrapper">
        @if (hintLabel) {
          <ids-hint [id]="_hintLabelId">{{ hintLabel }}</ids-hint>
        }
        <ng-content select="ids-hint:not([align='end'])"></ng-content>
        <div class="ids-adc-form-field-hint-spacer"></div>
        <ng-content select="ids-hint[align='end']"></ng-content>
      </div>
    }
  }
</div>
