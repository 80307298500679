import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { ErrorHandlerActions } from './error.state';

/** Handles global 401 error. */
export const handleError401$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(ErrorHandlerActions.handleError401),
      tap(() => router.navigate(['/login'])),
    ),
  { functional: true, dispatch: false },
);

/** Handles global 500 error. */
export const handleError404$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(ErrorHandlerActions.handleError404),
      tap(() => router.navigate(['/'])),
    ),
  { functional: true, dispatch: false },
);
