import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  standalone: true,
})
export class FormatPhonePipe implements PipeTransform {
  transform(value: string | number | undefined | null): any {
    if (!value) {
      return value;
    }

    if (typeof value === 'number') {
      value = String(value);
    }

    return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
  }
}
