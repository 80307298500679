import { Attribute, Directive, ElementRef, InjectionToken, Input } from '@angular/core';

let uniqueId = 0;

/**
 * Injection token that can be used to reference instances of `IdsError`. It serves as
 * alternative token to the actual `IdsError` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const IDS_ERROR = new InjectionToken<IdsError>('IdsError');

/** Single error message to be shown under a form field. */
@Directive({
  selector: 'ids-error, [idsError]',
  host: {
    class: 'ids-adc-form-field-error ids-adc-form-field-bottom-align',
    'aria-atomic': 'true',
    '[id]': 'id',
  },
  providers: [{ provide: IDS_ERROR, useExisting: IdsError }],
  standalone: true,
})
export class IdsError {
  @Input() id: string = `ids-adc-error-${uniqueId++}`;

  constructor(@Attribute('aria-live') ariaLive: string, elementRef: ElementRef) {
    // If no aria-live value is set add 'polite' as a default. This is preferred over setting
    // role='alert' so that screen readers do not interrupt the current task to read this aloud.
    if (!ariaLive) {
      elementRef.nativeElement.setAttribute('aria-live', 'polite');
    }
  }
}
