@if (!_isEditing) {
  <span class="ids-adc-chip-focus-overlay"></span>
}

<span
  class="adc-evolution-chip__cell adc-evolution-chip__cell--primary"
  role="gridcell"
  idsChipAction
  [tabIndex]="tabIndex"
  [disabled]="disabled"
  [attr.aria-label]="ariaLabel"
  [attr.aria-describedby]="_ariaDescriptionId"
>
  @if (leadingIcon) {
    <span class="adc-evolution-chip__graphic ids-adc-chip-graphic">
      <ng-content select="ids-chip-avatar, [idsChipAvatar]"></ng-content>
    </span>
  }

  <span class="adc-evolution-chip__text-label ids-adc-chip-action-label">
    @if (_isEditing) {
      @if (contentEditInput) {
        <ng-content select="[idsChipEditInput]"></ng-content>
      } @else {
        <span idsChipEditInput></span>
      }
    } @else {
      <ng-content></ng-content>
    }

    <span class="ids-adc-chip-primary-focus-indicator ids-adc-focus-indicator" aria-hidden="true"></span>
  </span>
</span>

@if (_hasTrailingIcon()) {
  <span class="adc-evolution-chip__cell adc-evolution-chip__cell--trailing" role="gridcell">
    <ng-content select="ids-chip-trailing-icon,[idsChipRemove],[idsChipTrailingIcon]"></ng-content>
  </span>
}

<span class="cdk-visually-hidden" [id]="_ariaDescriptionId">{{ ariaDescription }}</span>
