import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
//@ts-ignore
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateRangeModel } from './date-picker';

@Component({
  selector: 'ids-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IdsDatePicker implements OnInit, DoCheck, OnDestroy {
  private ngUnsubscribe = new Subject();
  private validText = '';
  private canDetectChanges = true;
  private isDisabled = false;

  @ViewChild('abinput') abinput!: ElementRef;
  @ViewChild('abicon') abicon!: ElementRef;
  @ViewChild('abspan') abspan!: ElementRef;

  @Input() validator: any;
  @Input() precision = 0;
  @Input() isStrict = true;
  @Input() scale = 0;
  @Input() text = '';
  @Input() type = 'text';
  @Input() labelText = '';
  @Input() placeholder = '';
  @Input() cssClass = '';
  @Input() cssClassRight = true;
  @Input() isAlignRight = false;
  @Input() unit = '';
  @Input() width = '100%';
  @Input() height = '32px';
  @Input() bgColor = '#FFFFFF';
  @Input() showErrorText = false;
  @Input() inputFormControl!: UntypedFormControl;
  @Input() showErrorsSubject = new Subject();
  @Input() setFocusSubject = new Subject();
  @Input() isEmitTextChange = false;
  @Input() iconAction!: () => void;
  @Input() tabindex = 0;
  @Input() maxDate!: Date;
  @Input() minDate!: Date;
  @Input() maxlength!: number;
  @Output() textChange = new EventEmitter();
  @Output() showTimeEvent = new EventEmitter();

  @Output() keyupenter = new EventEmitter();
  errorMessage = '';

  inputForm!: UntypedFormGroup;
  isShowErrors = false;

  guid!: string;
  dates!: Date[];
  tooltip!: NgbTooltip;

  ngOnChanges(changes: SimpleChanges) {
    // tslint:disable-next-line:forin
    for (const propName in changes) {
      if (propName === 'inputFormControl') {
        this.initFormControl();
      }
    }
  }

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.guid = Guid.create().toString();
    this.initFormControl();

    this.setFocusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.abinput && this.abinput.nativeElement) {
        this.abinput.nativeElement.focus();
      }
    });
  }

  initFormControl = () => {
    this.inputFormControl = this.inputFormControl ? this.inputFormControl : new UntypedFormControl(null, []);
    this.inputForm = new UntypedFormGroup({
      formInput: this.inputFormControl,
    });
  };

  ngDoCheck() {
    if (this.abinput?.nativeElement && this.canDetectChanges) {
      this.canDetectChanges = false;
      this.cdr.detectChanges();
    }
  }

  get input(): AbstractControl {
    return this.inputForm.get('formInput') as AbstractControl;
  }

  setMdFormStyle = () => {
    return {
      width: this.width,
    };
  };

  onInputFocus = (event: FocusEvent) => {
    if (this.abinput?.nativeElement) {
      (this.abinput?.nativeElement as HTMLInputElement).select();
    }
  };

  onKeyUpEnter = (event: Event) => {
    this.keyupenter.emit(event);
  };

  modelChanged = (event: any, abinput: HTMLInputElement) => {
    event = event ? event : '';
    if (this.isEmitTextChange) {
      this.setText(event, abinput);
    } else {
      this.isEmitTextChange = true;
    }
  };

  private setText = (event: any, abinput: HTMLInputElement) => {
    if (this.validator) {
      if (typeof this.validator === 'function' && this.validator.length === 3) {
        this.text = this.validator(event, this.precision, this.scale);
      } else if (typeof this.validator === 'function' && this.validator.length === 2) {
        this.text = this.validator(event, this.isStrict);
      } else {
        this.text = this.validator(event);
      }
      this.validText = this.text !== null ? this.text : this.validText;
      this.text = this.validText;
      abinput.value = this.validText;
    }
    if (this.input && this.input.value !== this.text) {
      this.input.setValue(this.text);
    }
    this.textChange.emit(this.text);
  };

  setInputStyle = () => {
    if (this.elementRef.nativeElement && this.abinput) {
      const el = this.elementRef.nativeElement as HTMLElement;
      const isDisabled = el.classList.contains('disabled');
      if (isDisabled !== this.isDisabled) {
        this.isDisabled = isDisabled;
        this.cdr.detectChanges();
      }
      if (isDisabled) {
        const input = this.abinput.nativeElement as HTMLElement;
        input.style.cssText = `border: 1px solid #ADB5BD!important; color: #ADB5BD!important; background-color: transparent; height: ${this.height}`;

        if (this.abicon) {
          const icon = this.abicon.nativeElement as HTMLElement;
          icon.style.cssText = 'color: #ADB5BD!important;';
        }
      } else {
        let paddingRight = '0.75rem';
        if (this.abicon) {
          const icon = this.abicon.nativeElement as HTMLElement;
          icon.style.cssText = '';
        }
        if (this.abspan && this.cssClassRight) {
          const span = this.abspan.nativeElement as HTMLElement;
          span.style.cssText = '';
          paddingRight = `${span.offsetWidth + 16}px`;
        }
        return {
          'background-color': this.bgColor,
          height: this.height,
          'padding-right': paddingRight,
          color: 'inherit',
        };
      }
    } else {
      this.canDetectChanges = true;
    }
    return {
      height: this.height,
    };
  };

  ngOnDestroy() {
    // unsubscribe change detection
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  setInputClass = () => {
    return {
      'ng-touched': (this.input.invalid && this.isShowErrors) || this.input.touched,
      'pr-2rem': this.cssClass && this.cssClassRight,
      'pl-3': (this.cssClass || this.unit) && !this.cssClassRight,
      selectable: this.labelText,
      selected: this.labelText && this.text,
      'text-right': this.isAlignRight,
    };
  };

  setIconClass = () => {
    return {
      'icon-right': this.cssClass && this.cssClassRight,
      'icon-left': this.cssClass && !this.cssClassRight,
    };
  };

  setUnitStyle = () => {
    let right = '0';
    if (this.abspan) {
      const span = this.abspan.nativeElement as HTMLElement;
      span.style.cssText = '';
      right = `${span.offsetWidth + 12}px`;
      span.style.cssText = this.cssClassRight ? `left: calc(100% - ${right}); top: 0;` : 'left: 0.5rem; top: 0;';
    }
    return {
      left: this.cssClassRight ? `calc(100% - ${right})` : '0.5rem',
      top: '0',
    };
  };

  onDateChanged = ($event: any, abinput: HTMLInputElement) => {
    this.modelChanged($event.actualDateFormatted, abinput);
  };

  datePickerClick = (tooltip: NgbTooltip | null) => {
    if (tooltip) {
      this.tooltip = tooltip;
    }
    if (!this.tooltip?.isOpen()) {
      const date = moment(this.text);
      this.dates = [date.isValid() ? new Date(this.text) : new Date()];
      this.tooltip.open();
      setTimeout(() => {
        this.cdr.detectChanges();
      }, 100);
    } else {
      this.tooltip?.close();
    }
  };

  dateRangePresetsChange = (dateRangeModel: DateRangeModel) => {
    setTimeout(() => {
      if (dateRangeModel && dateRangeModel.stringValue) {
        this.text = dateRangeModel.strDates?.length ? dateRangeModel.strDates[0] : this.text;
      } else {
        this.text = '';
      }
      this.cdr.detectChanges();
    });
  };

  setDateRangeStyle = () => {
    const element = this.abinput.nativeElement as HTMLElement;
    const rect = element.getBoundingClientRect();
    const left = rect.left > element.offsetWidth ? `-${element.offsetWidth}px` : `-${rect.left}px`;

    return {
      left,
      top: 0,
    };
  };

  onIconClick = () => {
    if (this.iconAction) {
      this.iconAction();
    }
  };

  timePickerClick = (event: Event) => {
    this.showTimeEvent.emit(event);
  };
}
