import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  getItem<T>(key: string): Observable<T | string | null> {
    const serialized = localStorage.getItem(key);

    if (serialized) {
      if (typeof serialized === 'string') {
        return of(serialized);
      }

      const data: T = JSON.parse(serialized);
      return of(data);
    }

    return of(null);
  }

  setItem<T>(key: string, data: T): Observable<string> {
    const toSave = typeof data !== 'string' ? JSON.stringify(data) : data;
    localStorage.setItem(key, toSave);
    return of(toSave);
  }

  removeItem(key: string): Observable<boolean> {
    localStorage.removeItem(key);
    return of(true);
  }
}
