<div class="ids-date-range flex w-auto flex-col items-center text-base" [ngStyle]="setdateRangeStyle()">
  @if (title) {
    <h3 class="text-blue-40 flex items-center pb-2">
      {{ title }}
    </h3>
  }

  <bs-datepicker-inline
    class="bs-datepicker-inline"
    [bsConfig]="bsConfig"
    [bsValue]="bsInlineRangeValue"
    [dateCustomClasses]="selectedDates"
    [minDate]="minDate"
    [maxDate]="maxDate"
    (bsValueChange)="valueChanged()"
  >
  </bs-datepicker-inline>

  @if (isBtnToday) {
    <button ids-adc-button class="mb-2 w-fit px-2 py-1" (click)="onTodayClick()">Today</button>
  }
</div>
