import { Directive } from '@angular/core';

/** Directive that should be applied to the text element to be rendered in the toast. */
@Directive({
  selector: `[idsToastLabel]`,
  standalone: true,
  host: {
    class: 'ids-adc-toast-label adc-toast__label',
  },
})
export class IdsToastLabel {}

/** Directive that should be applied to the element containing the toast's action buttons. */
@Directive({
  selector: `[idsToastActions]`,
  standalone: true,
  host: {
    class: 'ids-adc-toast-actions adc-toast__actions',
  },
})
export class IdsToastActions {}

/** Directive that should be applied to each of the toast's action buttons. */
@Directive({
  selector: `[idsToastAction]`,
  standalone: true,
  host: {
    class: 'ids-adc-toast-action adc-toast__action',
  },
})
export class IdsToastAction {}
