/**
 * Strips a telephone number of all special characters.
 *
 * @param value the telephone number to parse
 * @returns telephone number with only digits
 */
export const stripSpecialCharacters = (value: string | null = null): string => {
  if (!value) {
    return '';
  }

  return value.replace(/\D/g, '');
};
