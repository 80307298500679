import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { IdsButton } from '../../components/button/button.component';
import { IDS_TOAST_DATA } from './toast-config';
import { IdsToastAction, IdsToastActions, IdsToastLabel } from './toast-content.directive';
import { IdsToastRef } from './toast-ref';

/**
 * Interface for a simple toast component that has a message and a single action.
 */
export interface TextOnlyToast {
  data: { message: string; action: string };
  toastRef: IdsToastRef<TextOnlyToast>;
  action: () => void;
  hasAction: boolean;
}

@Component({
  selector: 'simple-toast',
  standalone: true,
  imports: [IdsButton, IdsToastLabel, IdsToastActions, IdsToastAction],
  exportAs: 'idsToast',
  template: `
    <div idsToastLabel>
      {{ data.message }}
    </div>

    @if (hasAction) {
      <div idsToastActions>
        <button ids-adc-button idsToastAction (click)="action()">
          {{ data.action }}
        </button>
      </div>
    }
  `,
  // The label and actions expect to be in a flex container. Since this component adds another
  // wrapping layer to the adc-toast__surface, it should also include flex display.
  styles: `
    .ids-adc-simple-toast {
      @apply flex;
    }
  `,
  host: {
    class: 'ids-adc-simple-toast',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleToast implements TextOnlyToast {
  constructor(
    public toastRef: IdsToastRef<SimpleToast>,
    @Inject(IDS_TOAST_DATA) public data: { message: string; action: string },
  ) {}

  /** Performs the action on the toast. */
  action(): void {
    this.toastRef.dismissWithAction();
  }

  /** If the action button should be shown. */
  get hasAction(): boolean {
    return !!this.data.action;
  }
}
