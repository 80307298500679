<div class="adc-toast__surface ids-adc-toast-surface">
  <!--
      This outer label wrapper will have the class `adc-toast__label` applied if
      the attached template/component does not contain it.
    -->
  <div class="ids-adc-toast-label" #label>
    <!-- Initialy holds the toast content, will be empty after announcing to screen readers. -->
    <div aria-hidden="true">
      <ng-template cdkPortalOutlet />
    </div>

    <!-- Will receive the toast content from the non-live div, move will happen a short delay after opening -->
    <div [attr.aria-live]="_live" [attr.role]="_role" [attr.id]="_liveElementId"></div>
  </div>
</div>
