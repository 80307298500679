import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform, getSupportedInputTypes } from '@angular/cdk/platform';
import { AutofillMonitor } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  isDevMode,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ErrorStateMatcher } from '../../core';
import { ErrorStateTracker } from '../../core/error-state';
import { IdsFormFieldControl } from '../form-field/form-field-control.directive';
import { IDS_FORM_FIELD, IdsFormFieldComponent } from '../form-field/form-field.component';
import { getIdsInputUnsupportedTypeError } from './input-errors';
import { IDS_INPUT_VALUE_ACCESSOR } from './input-value-accessor';

// Invalid input type. Using one of these will throw an IdsInputUnsupportedTypeError.
const IDS_INPUT_INVALID_TYPES = ['button', 'checkbox', 'file', 'hidden', 'image', 'radio', 'range', 'reset', 'submit'];

let uniqueId = 0;

const IDS_INPUT_HOST = {
  class: 'ids-adc-input',
  '[class.ids-adc-form-field-textarea-control]': '_isInFormField && _isTextarea',
  '[class.ids-adc-form-field-input-control]': '_isInFormField',
  '[class.adc-text-field__input]': '_isInFormField',
  '[class.ids-adc-native-select-inline]': '_isInlineSelect()',
  // Native input properties that are overwritten by Angular inputs need to be synced with
  // the native input element. Otherwise property bindings for those don't work.
  '[id]': 'id',
  '[disabled]': 'disabled',
  '[required]': 'required',
  '[attr.name]': 'name || null',
  // Only mark the input as invalid for assistive technology if it has a value since the
  // state usually overlaps with `aria-required` when the input is empty and can be redundant.
  '[attr.aria-invalid]': '(empty && required) ? null : errorState',
  '[attr.aria-required]': 'required',
  // Native input properties that are overwritten by Angular inputs need to be synced with
  // the native input element. Otherwise property bindings for those don't work.
  '[attr.id]': 'id',
  '(focus)': '_focusChanged(true)',
  '(blur)': '_focusChanged(false)',
  '(input)': '_onInput()',
};

@Directive({
  selector: `input[idsInput], testarea[idsInput], input[idsNativeControl], textarea[nativeControl]`,
  exportAs: 'idsInput',
  standalone: true,
  host: IDS_INPUT_HOST,
  providers: [{ provide: IdsFormFieldControl, useExisting: IdsInput }],
})
export class IdsInput implements IdsFormFieldControl<any>, OnChanges, OnDestroy, AfterViewInit, DoCheck {
  protected _uid = `ids-input-${uniqueId++}`;
  protected _previousNativeValue: any;
  private _inputValueAccessor: { value: any };
  private _previousPlaceholder!: string | null;
  private _errorStateTracker: ErrorStateTracker;

  /** Check if component is rendered on the server. */
  readonly _isServer: boolean;

  /** Check if component is a native html select. */
  readonly _isNativeSelect: boolean;

  /** Check if the component is a textarea. */
  readonly _isTextarea: boolean;

  /** Check if the input is inside of a form field. */
  readonly _isInFormField: boolean;

  /**
   * Implemented as part of IdsFormFieldControl.
   */
  focused: boolean = false;

  /**
   * Implemented as part of IdsFormFieldControl.
   */
  readonly stateChanges: Subject<void> = new Subject<void>();

  /**
   * Implemented as part of IdsFormFieldControl.
   */
  controlType: string = 'ids-input';

  /**
   * Implemented as part of IdsFormFieldControl.
   */
  autofilled = false;

  /**
   * Implemented as part of IdsFormFieldControl.
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  protected _disabled = false;

  /** The input's unique id. Implemented as part of IdsFormFieldControl. */
  @Input()
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value || this._uid;
  }
  protected _id!: string;

  /** The input's placeholder text. Implemented as part of IdsFormFieldControl. */
  @Input() placeholder = '';

  /** Name of the input. */
  @Input() name!: string;

  /** The input's validation attribute. Implemented as part of IdsFormFieldControl. */
  @Input()
  get required(): boolean {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  protected _required: boolean | undefined;

  /** The input's type. */
  @Input()
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value || 'text';
    this._validateType();

    // When using Angular inputs, developers are no longer able to set the properties on the native
    // input element. To ensure that bindings for `type` work, we need to sync the setter
    // with the native property. Textarea elements don't support the type property or attribute.
    if (!this._isTextarea && getSupportedInputTypes().has(this._type)) {
      (this._elementRef.nativeElement as HTMLInputElement).type = this._type;
    }
  }
  protected _type = 'text';

  /** An object used to control when error messages are shown. */
  @Input()
  get errorStateMatcher() {
    return this._errorStateTracker.matcher;
  }
  set errorStateMatcher(value: ErrorStateMatcher) {
    this._errorStateTracker.matcher = value;
  }

  /**
   * Implemented as part of IdsFormFieldControl.
   */
  @Input('aria-describedby') userAriaDescribedBy!: string;

  /** The input's value. Implemented as part of IdsFormFieldControl. */
  @Input()
  get value(): string {
    return this._inputValueAccessor.value;
  }
  set value(value: any) {
    if (value !== this.value) {
      this._inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }

  /** Sets the element as readonly. */
  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;

  /** Sets the input in an error state. */
  get errorState() {
    return this._errorStateTracker.errorState;
  }
  set errorState(value: boolean) {
    this._errorStateTracker.errorState = value;
  }

  protected _neverEmptyInputTypes = ['date', 'datetime', 'datetime-local', 'month', 'time', 'week'].filter((t) =>
    getSupportedInputTypes().has(t),
  );

  constructor(
    protected _elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    protected _platform: Platform,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() @Self() @Inject(IDS_INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
    private _autofillMonitor: AutofillMonitor,
    ngZone: NgZone,
    @Optional() @Inject(IDS_FORM_FIELD) protected _formField?: IdsFormFieldComponent,
  ) {
    const element = this._elementRef.nativeElement;
    const nodeName = element.nodeName.toLowerCase();

    // If no input value accessor was explicitly specified, use the element as the input value
    // accessor.
    this._inputValueAccessor = inputValueAccessor || element;

    this._previousNativeValue = this.value;

    // Force setter to be called in case id was not specified.
    this.id = this.id;

    // On some versions of iOS the caret gets stuck in the wrong place when holding down the delete
    // key. In order to get around this we need to "jiggle" the caret loose. Since this bug only
    // exists on iOS, we only bother to install the listener on iOS.
    if (_platform.IOS) {
      ngZone.runOutsideAngular(() => {
        _elementRef.nativeElement.addEventListener('keyup', this._iOSKeyupListener);
      });
    }

    this._errorStateTracker = new ErrorStateTracker(
      defaultErrorStateMatcher,
      ngControl,
      parentFormGroup,
      parentForm,
      this.stateChanges,
    );
    this._isServer = !this._platform.isBrowser;
    this._isNativeSelect = nodeName === 'select';
    this._isTextarea = nodeName === 'textarea';
    this._isInFormField = !!_formField;

    if (this._isNativeSelect) {
      this.controlType = (element as HTMLSelectElement).multiple ? 'ids-native-select-multiple' : 'ids-native-select';
    }
  }

  ngAfterViewInit() {
    if (this._platform.isBrowser) {
      this._autofillMonitor.monitor(this._elementRef.nativeElement).subscribe((event) => {
        this.autofilled = event.isAutofilled;
        this.stateChanges.next();
      });
    }
  }

  ngOnChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();

    if (this._platform.isBrowser) {
      this._autofillMonitor.stopMonitoring(this._elementRef.nativeElement);
    }

    if (this._platform.IOS) {
      this._elementRef.nativeElement.removeEventListener('keyup', this._iOSKeyupListener);
    }
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();

      // Since the input isn't a `ControlValueAccessor`, we don't have a good way of knowing when
      // the disabled state has changed. We can't use the `ngControl.statusChanges`, because it
      // won't fire if the input is disabled with `emitEvents = false`, despite the input becoming
      // disabled.
      if (this.ngControl.disabled !== null && this.ngControl.disabled !== this.disabled) {
        this.disabled = this.ngControl.disabled;
        this.stateChanges.next();
      }
    }

    // We need to dirty-check the native element's value, because there are some cases where
    // we won't be notified when it changes (e.g. the consumer isn't using forms or they're
    // updating the value using `emitEvent: false`).
    this._dirtyCheckNativeValue();

    // We need to dirty-check and set the placeholder attribute ourselves, because whether it's
    // present or not depends on a query which is prone to "changed after checked" errors.
    this._dirtyCheckPlaceholder();
  }

  /** Focuses the input. */
  focus(options?: FocusOptions): void {
    this._elementRef.nativeElement.focus(options);
  }

  /** Refreshes the error state of the input. */
  updateErrorState() {
    this._errorStateTracker.updateErrorState();
  }

  /** Callback for the cases where the focused state of the input changes. */
  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  _onInput() {
    // This is a noop function and is used to let Angular know whenever the value changes.
    // Angular will run a new change detection each time the `input` event has been dispatched.
    // It's necessary that Angular recognizes the value change, the placeholder won't recognize the
    // value changes and will not disappear.
    // Listening to the input event wouldn't be necessary when the input is using the
    // FormsModule or ReactiveFormsModule, because Angular forms also listens to input events.
  }

  /** Does some manual dirty checking on the native input `value` property. */
  protected _dirtyCheckNativeValue() {
    const newValue = this._elementRef.nativeElement.value;

    if (this._previousNativeValue !== newValue) {
      this._previousNativeValue = newValue;
      this.stateChanges.next();
    }
  }

  /** Does some manual dirty checking on the native input `placeholder` attribute. */
  private _dirtyCheckPlaceholder() {
    const placeholder = this._getPlaceholder();
    if (placeholder !== this._previousPlaceholder) {
      const element = this._elementRef.nativeElement;
      this._previousPlaceholder = placeholder;
      placeholder ? element.setAttribute('placeholder', placeholder) : element.removeAttribute('placeholder');
    }
  }

  /** Gets the current placeholder of the form field. */
  protected _getPlaceholder(): string | null {
    return this.placeholder || null;
  }

  /** Make sure the input is a supported type. */
  protected _validateType() {
    if (IDS_INPUT_INVALID_TYPES.indexOf(this._type) > -1 && isDevMode()) {
      throw getIdsInputUnsupportedTypeError(this._type);
    }
  }

  /** Checks if the input type is one of the types that are never empty. */
  protected _isNeverEmpty() {
    return this._neverEmptyInputTypes.indexOf(this._type) > -1;
  }

  /** Checks if the input is invalid based on the native validation. */
  protected _isBadInput() {
    // The `validity` property won't be present on platform-server.
    let validity = (this._elementRef.nativeElement as HTMLInputElement).validity;
    return validity && validity.badInput;
  }

  /**
   * Implemented as part of IdsFormFieldControl.
   */
  get empty(): boolean {
    return !this._isNeverEmpty() && !this._elementRef.nativeElement.value && !this._isBadInput() && !this.autofilled;
  }

  /**
   * Sets `aria-describedby` accessibility attribute. Implemented as part of IdsFormFieldControl.
   */
  setDescribedByIds(ids: string[]) {
    if (ids.length) {
      this._elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
    } else {
      this._elementRef.nativeElement.removeAttribute('aria-describedby');
    }
  }

  /**
   * Implemented as part of IdsFormFieldControl.
   */
  onContainerClick() {
    // Do not re-focus the input element if the element is already focused. Otherwise it can happen
    // that someone clicks on a time input and the cursor resets to the "hours" field while the
    // "minutes" field was actually clicked. See: https://github.com/angular/components/issues/12849
    if (!this.focused) {
      this.focus();
    }
  }

  /** Check if the form control is a native select that is displayed inline. */
  _isInlineSelect(): boolean {
    const element = this._elementRef.nativeElement as HTMLSelectElement;
    return this._isNativeSelect && (element.multiple || element.size > 1);
  }

  private _iOSKeyupListener = (event: Event): void => {
    const el = event.target as HTMLInputElement;

    // Note: We specifically check for 0, rather than `!el.selectionStart`, because the two
    // indicate different things. If the value is 0, it means that the caret is at the start
    // of the input, whereas a value of `null` means that the input doesn't support
    // manipulating the selection range. Inputs that don't support setting the selection range
    // will throw an error so we want to avoid calling `setSelectionRange` on them. See:
    // https://html.spec.whatwg.org/multipage/input.html#do-not-apply
    if (!el.value && el.selectionStart === 0 && el.selectionEnd === 0) {
      // Note: Just setting `0, 0` doesn't fix the issue. Setting
      // `1, 1` fixes it for the first time that you type text and
      // then hold delete. Toggling to `1, 1` and then back to
      // `0, 0` seems to completely fix it.
      el.setSelectionRange(1, 1);
      el.setSelectionRange(0, 0);
    }
  };
}
