import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  booleanAttribute,
  inject,
} from '@angular/core';

/** Object that can be used to configure the default options for the button component. */
export interface IdsButtonConfig {
  /** Whether disabled buttons should be interactive. */
  disabledInteractive?: boolean;
}

/** Injection token that can be used to provide the default options the button component. */
export const IDS_BUTTON_CONFIG = new InjectionToken<IdsButtonConfig>('IDS_BUTTON_CONFIG');

/** Shared host configuration for all buttons */
export const IDS_BUTTON_HOST = {
  // Sets the disabled state of the button.
  '[attr.disabled]': '_getDisabledAttribute()',
  // Sets accessibility attribute to indicate disabled state.
  '[attr.aria-disabled]': '_getAriaDisabled()',
  '[class.ids-adc-button-disabled]': 'disabled',
  '[class.ids-adc-button-disabled-interactive]': 'disabledInteractive',
  // Add a class that applies to all buttons. This makes it easier to target if somebody
  // wants to target all Ids buttons.
  '[class.ids-adc-button-base]': 'true',
  '[class]': 'color ? "ids-" + color : ""',
  '[class.active]': 'active',
};

/** List of classes to add to buttons instances based on host attribute selector. */
const HOST_SELECTOR_ADC_CLASS_PAIR: { attribute: string; variantClasses: string[] }[] = [
  {
    attribute: 'ids-adc-button',
    variantClasses: ['ids-adc-button'],
  },
  /** The following attributes are the button's available variants. */
  {
    attribute: 'ids-outline-button',
    variantClasses: ['ids-adc-button', 'ids-outline-button'],
  },
  {
    attribute: 'ids-ghost-button',
    variantClasses: ['ids-adc-button', 'ids-ghost-button'],
  },
  {
    attribute: 'ids-slim-button',
    variantClasses: ['ids-adc-button', 'ids-slim-button'],
  },
  /** The following attributes are the button's available shapes. */
  {
    attribute: 'rounded-full',
    variantClasses: ['ids-adc-button', 'ids-rounded-full'],
  },
  {
    attribute: 'rounded-left',
    variantClasses: ['ids-adc-button', 'ids-rounded-left'],
  },
  {
    attribute: 'rounded-right',
    variantClasses: ['ids-adc-button', 'ids-rounded-right'],
  },
  {
    attribute: 'rounded-none',
    variantClasses: ['ids-adc-button', 'ids-rounded-none'],
  },
  {
    attribute: 'full-width',
    variantClasses: ['ids-adc-button', 'ids-full-width-button'],
  },
];

/** The button's availale colors. */
export type IdsButtonPalette = 'primary' | 'secondary' | 'tertiary' | 'critical' | 'cta';

/** Base class for all buttons. */
@Directive()
export class IdsButtonBase implements AfterViewInit, OnDestroy {
  private readonly _focusMonitor = inject(FocusMonitor);

  /** Theme color palette of the button */
  @Input() color: IdsButtonPalette = 'primary';

  /** Whether the button is disabled. */
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = value;
  }
  private _disabled = false;

  /** `aria-disabled` value of the button. */
  @Input({ transform: booleanAttribute, alias: 'aria-disabled' })
  ariaDisabled: boolean | undefined;

  /** Whether the button is active. */
  @Input({ transform: booleanAttribute })
  get active(): boolean {
    return this._active;
  }
  set active(value: any) {
    this._active = value;
  }
  private _active = false;

  /**
   * Natively disabled buttons prevent focus and any pointer events from reaching the button.
   * In some scenarios this might not be desirable, because it can prevent users from finding out
   * why the button is disabled (e.g. via tooltip).
   *
   * Enabling this input will change the button so that it is styled to be disabled and will be
   * marked as `aria-disabled`, but it will allow the button to receive events and focus.
   *
   * Note that by enabling this, you need to set the `tabindex` yourself if the button isn't
   * meant to be tabbable and you have to prevent the button action (e.g. form submissions).
   */
  @Input({ transform: booleanAttribute })
  disabledInteractive: boolean;

  constructor(
    public _elementRef: ElementRef,
    public _platform: Platform,
    public _ngZone: NgZone,
    public _animationMode?: string,
  ) {
    const config = inject(IDS_BUTTON_CONFIG, { optional: true });
    const element = _elementRef.nativeElement;
    const classList = (element as HTMLElement).classList;

    this.disabledInteractive = config?.disabledInteractive ?? false;

    // For each of the variant selectors that is present in the button's host
    // attributes, add the correct corresponding variant classes.
    for (const { attribute, variantClasses } of HOST_SELECTOR_ADC_CLASS_PAIR) {
      if (element.hasAttribute(attribute)) {
        classList.add(...variantClasses);
      }
    }
  }

  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef, true);
  }

  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  /** Focuses the button. */
  focus(origin: FocusOrigin = 'program', options?: FocusOptions): void {
    if (origin) {
      this._focusMonitor.focusVia(this._elementRef.nativeElement, origin, options);
    } else {
      this._elementRef.nativeElement.focus(options);
    }
  }

  protected _getAriaDisabled() {
    if (this.ariaDisabled != null) {
      return this.ariaDisabled;
    }

    return this.disabled && this.disabledInteractive ? true : null;
  }

  protected _getDisabledAttribute() {
    return this.disabledInteractive || !this.disabled ? null : true;
  }
}
