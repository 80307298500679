import { Directive, InjectionToken, Input } from '@angular/core';

/**
 * Injection token that can be used to reference instances of `IdsSuffix`. It serves as
 * alternative token to the actual `IdsSuffix` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const IDS_SUFFIX = new InjectionToken<IdsSuffix>('IdsSuffix');

/** Suffix to be placed at the end of the form field. */
@Directive({
  selector: '[idsSuffix], [idsIconSuffix], [idsTextSuffix]',
  providers: [{ provide: IDS_SUFFIX, useExisting: IdsSuffix }],
  standalone: true,
})
export class IdsSuffix {
  @Input('idsTextSuffix')
  set _isTextSelector(value: '') {
    this._isText = true;
  }

  _isText = false;
}
