import { IconNames } from '../icon';

/** The ui component type to display. */
export type DialogUiType = 'dialog' | 'banner' | 'toast';

/** The dialog popup type to display. */
export type DialogStatus = 'info' | 'success' | 'confirmation' | 'warning' | 'error';

/** Data specific to display a toast. */
export interface NotificationData {
  /** The ui component type to display. */
  type?: DialogUiType;

  /** The toast notification's status indiciation. */
  status?: DialogStatus;

  /** The toast notification's label. */
  label?: string;

  /** The toast notification's messages represented by a list. */
  messages?: string[];

  /** The toast notification's icon. */
  icon?: IconNames;

  /** The toast notification's color pallette. */
  color?: {
    foreground: string;
    background: string;
    border: string;
  };
}

/**
 * Color map of status types to css variables.
 *
 * The css variables
 */
export const notificationStatusColorMap = new Map<DialogStatus, NotificationData>([
  [
    'info',
    {
      icon: 'info',
      color: {
        foreground: 'text-primary',
        background: 'bg-primary',
        border: 'border-primary',
      },
    },
  ],
  [
    'success',
    {
      icon: 'check',
      color: {
        foreground: 'text-confirm',
        background: 'bg-confirm',
        border: 'border-confirm',
      },
    },
  ],
  [
    'warning',
    {
      icon: 'exclamation-alert',
      color: {
        foreground: 'text-alert',
        background: 'bg-alert',
        border: 'border-alert',
      },
    },
  ],
  [
    'error',
    {
      icon: 'close',
      color: {
        foreground: 'text-critical',
        background: 'bg-critical',
        border: 'border-critical',
      },
    },
  ],
]);
