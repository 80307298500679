import { Pipe, PipeTransform } from '@angular/core';
//@ts-ignore
import moment from 'moment';

@Pipe({
  name: 'ageInYears',
  standalone: true,
})
export class AgeInYearsPipe implements PipeTransform {
  transform(date?: string): any {
    if (!date) {
      return null;
    }

    const dob = moment(date, 'MM/DD/YYYY');
    const today = moment();

    if (dob.isValid()) {
      const age = today.diff(dob, 'years');
      return `${age}yo`;
    }

    return null;
  }
}
