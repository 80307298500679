import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
//@ts-ignore
import moment from 'moment';
import { BsDaterangepickerInlineConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { DateRangeModel, DateRangePresets, DropdownItem } from './date-picker';

const monthObjects = [
  {
    label: 'January',
    val: 'Jan',
  },
  {
    label: 'February',
    val: 'Feb',
  },
  {
    label: 'March',
    val: 'Mar',
  },
  {
    label: 'April',
    val: 'Apr',
  },
  {
    label: 'May',
    val: 'May',
  },
  {
    label: 'June',
    val: 'Jun',
  },
  {
    label: 'July',
    val: 'Jul',
  },
  {
    label: 'August',
    val: 'Aug',
  },
  {
    label: 'September',
    val: 'Sep',
  },
  {
    label: 'October',
    val: 'Oct',
  },
  {
    label: 'November',
    val: 'Nov',
  },
  {
    label: 'December',
    val: 'Dec',
  },
];

@Component({
  selector: 'ids-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IdsDateRange implements OnChanges, OnInit, AfterViewInit, AfterViewChecked {
  @Input() title = '';
  @Input() holidays: any[] = [];
  @Input() dates: Date[] = [];
  @Input() selectedNumberOf = '';
  @Input() selectedPeriodType: DropdownItem = {
    id: '1',
    name: 'Days',
  } as DropdownItem;
  @Input() dateRangePresets: DateRangePresets = DateRangePresets.Custom;
  @Input() cssClass!: string;
  @Input() isBtnToday = false;
  @Input() maxDate!: Date;
  @Input() minDate!: Date;

  @Output() dateRangePresetsChanged = new EventEmitter<DateRangeModel>();
  @Output() dateChanged = new EventEmitter();
  @Output() close = new EventEmitter();

  selectedDates: DatepickerDateCustomClasses[] = [];

  bsInlineValue = new Date();
  bsInlineRangeValue: Date | undefined = undefined;
  bsConfig = {
    customTodayClass: 'calendar-today',
    selectFromOtherMonth: true,
    containerClass: '',
    showWeekNumbers: false,
    formatMonth: 'MM',
  } as Partial<BsDaterangepickerInlineConfig>;

  dateHoliday: DatepickerDateCustomClasses[] = [];
  selectedDays: DatepickerDateCustomClasses[] = [];
  selectedCustomDays: DropdownItem[] = [];

  keyCode!: string;

  ctrl = '{CTRL}';
  shift = '{SHIFT}';

  stringValue = '';
  numberOf = '';
  periodTypes: DropdownItem[] = [
    {
      id: '1',
      name: 'Days',
    } as DropdownItem,
    {
      id: '2',
      name: 'Weeks',
    } as DropdownItem,
    {
      id: '3',
      name: 'Months',
    } as DropdownItem,
  ];

  dateRangePresetsEnum = DateRangePresets;
  startDate = '';
  endDate = '';
  isFirstClick = true;

  constructor(public elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setDateRangePresets();
    this.setHolidays();
  }

  ngOnInit(): void {
    this.setDateRangePresets();
    this.setHolidays();
  }

  ngAfterViewInit(): void {
    if (this.elementRef.nativeElement && this.cssClass) {
      (this.elementRef.nativeElement as HTMLElement).classList.add(this.cssClass);
    }
  }

  ngAfterViewChecked(): void {
    let months = Array.from((this.elementRef.nativeElement as HTMLElement)?.getElementsByClassName('current'));
    months?.forEach((m: Element) => {
      m.childNodes.forEach((cnode: any) => {
        const monthObj = monthObjects.find((mo) => mo.label === cnode.innerText);
        if (monthObj) {
          cnode.innerText = monthObj.val;
        }
      });
    });

    months = Array.from((this.elementRef.nativeElement as HTMLElement)?.querySelectorAll('table.months tbody td span'));
    months?.forEach((m: any) => {
      const monthObj = monthObjects.find((mo) => mo.label === m.innerText);
      if (monthObj) {
        m.innerText = monthObj.val;
      }
    });

    const days = Array.from(
      (this.elementRef.nativeElement as HTMLElement)?.querySelectorAll('ids-date-range .days.weeks tbody td span'),
    );
    days?.forEach((d) => {
      d.addEventListener('click', this.selectDayClick);
    });
  }

  @HostListener('window:click', ['$event.target'])
  mouseClick(targetElement: EventTarget) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && !this.isFirstClick) {
      this.isFirstClick = true;
      this.onClose();
    } else {
      this.isFirstClick = false;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    this.keyCode = '';
  }

  @HostListener('window:keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {
    if (event.key === 'Shift' || event.key === 'Control') {
      this.keyCode = event.key;
    }
  }

  changeDateRangePresets(dateRangePresets: DateRangePresets) {
    this.dateRangePresets = dateRangePresets;
    this.selectedPeriodType = {
      id: '1',
      name: 'Days',
    } as DropdownItem;
    this.selectedNumberOf = '';
    this.stringValue = '';
    this.setDateRangePresets();
  }

  /**
   * Sets the date from the popover.
   *
   * NOTE: maintain this arrow function so `this` context is maintained.
   */
  selectDayClick = (event: Event) => {
    this.dateRangePresets = DateRangePresets.Custom;

    const selectedDate = (event.target as HTMLElement).innerText;
    const bsCalendarLayout = (event.target as HTMLElement).closest('bs-calendar-layout');

    if (bsCalendarLayout) {
      const date = Array.from(bsCalendarLayout.getElementsByClassName('current'));

      let dateStr = '';

      date.forEach((d) => {
        dateStr = `${dateStr ? dateStr + '/' : ''}${d ? (d as HTMLElement).innerText : ''}`;
      });

      dateStr = `${selectedDate}/${dateStr}`;
      this.bsValueChange(new Date(dateStr));
    }

    this.emit();
  };

  selectedNumberOfChange() {
    if (!+this.selectedNumberOf) {
      this.clearCalendar();
    }
    let val = parseInt(this.selectedNumberOf, 10);
    if (!isNaN(val)) {
      let isUpcoming = false;
      if (this.dateRangePresets === this.dateRangePresetsEnum.Upcoming) {
        val = val * -1;
        isUpcoming = true;
      }
      this.setCalendarRangeCount(val, this.selectedPeriodType.name.toLowerCase(), new Date(), isUpcoming);

      this.stringValue = `${isUpcoming ? 'Upcoming' : 'Previous'} ${isUpcoming ? -1 * val : val} ${this.selectedPeriodType.name}`;
      this.emit();
    }
  }

  selectedCustomDaysChange(items: DropdownItem[]) {
    this.clearCalendar();
    this.stringValue = '';
    items.forEach((d, index) => {
      this.keyCode = 'Control';
      this.bsValueChange(new Date(d.name));
      this.stringValue = `${this.stringValue} ${index === items.length - 1 ? d.name : d.name + ','}`;
    });
    this.emit();
  }

  clearCalendar() {
    this.selectedDays = [];
    this.selectedDates = this.selectedDates.filter(
      (cc) =>
        !cc.classes.includes('select-custom-day') &&
        !cc.classes.includes('select-start-custom') &&
        !cc.classes.includes('select-end-custom') &&
        !cc.classes.includes('in-range-custom'),
    );
    this.setRangeValue();
  }

  compareDates(date1: Date, date2: Date): number {
    if (date1.getTime() < date2.getTime()) {
      return -1;
    }
    if (date1.getTime() > date2.getTime()) {
      return 1;
    }

    return 0;
  }

  onDateRangeChange() {
    if (this.dateRangePresets !== DateRangePresets.DateRange) {
      return;
    }

    if (this.dates && this.dates.length) {
      const sDates = this.dates.sort(this.compareDates);
      this.startDate = moment(sDates[0]).format('MM/DD/YYYY');
      this.endDate = moment(sDates[sDates.length - 1]).format('MM/DD/YYYY');
    }

    const dateRegex = /^(0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)(\d){2}/i;

    const startDateResult = dateRegex.test(this.startDate);
    const startDate = moment(this.startDate, 'MM/DD/YYYY');

    const endDateResult = dateRegex.test(this.endDate);
    const endtDate = moment(this.endDate, 'MM/DD/YYYY');

    if (startDateResult && startDate.isValid() && endDateResult && endtDate.isValid()) {
      const customClasses = [];
      this.clearCalendar();
      const startDateJs = new Date(startDate.format('MM/DD/YYYY'));
      customClasses.push('select-start-custom');
      const sd = {
        date: startDateJs,
        classes: customClasses,
      } as DatepickerDateCustomClasses;
      this.selectedDays.push(sd);
      const endDateJs = new Date(endtDate.format('MM/DD/YYYY'));
      this.selectDateRange(endDateJs, null);
      this.stringValue = `${startDate.format('MM/DD/YYYY')} - ${endtDate.format('MM/DD/YYYY')}`;
      this.emit();
    } else {
      if (startDateResult && startDate.isValid()) {
        const startDateJs = new Date(startDate.format('MM/DD/YYYY'));
        this.selectDefault(startDateJs, null);
        this.stringValue = `${startDate.format('MM/DD/YYYY')} - ${startDate.format('MM/DD/YYYY')}`;
        this.emit();
      }
      if (endDateResult && endtDate.isValid()) {
        const endDateJs = new Date(endtDate.format('MM/DD/YYYY'));
        this.selectDefault(endDateJs, null);
        this.stringValue = `${endtDate.format('MM/DD/YYYY')} - ${endtDate.format('MM/DD/YYYY')}`;
        this.emit();
      }
    }
  }

  emit() {
    this.setRangeValue();
    const dates = this.sortedSelectedDates();

    let strDates;

    if (dates && dates.length) {
      strDates = dates.map((d) => {
        return moment(d).format('MM/DD/YYYY');
      });
    }

    this.dateRangePresetsChanged.emit({
      dateRangePresets: this.dateRangePresets,
      selectedNumberOf: this.selectedNumberOf,
      selectedPeriodType: this.selectedPeriodType,
      strDates: strDates || [],
      stringValue: this.stringValue,
    });
  }

  valueChanged() {
    this.dateChanged.emit();
  }

  onClose() {
    this.close.emit();
  }

  setdateRangeStyle() {
    return {
      padding: this.title ? '10px 8px' : '0',
      background: !this.cssClass ? '#141A2F' : 'inherit',
    };
  }

  onTodayClick() {
    this.bsInlineRangeValue = new Date();
    this.bsValueChange(this.bsInlineRangeValue);
    this.emit();
  }

  private setDateRangePresets() {
    if (this.dateRangePresets !== 0 && !this.dateRangePresets) {
      this.selectedDates = [];
    }

    this.clearCalendar();

    switch (+this.dateRangePresets) {
      case DateRangePresets.Today: {
        const date = new Date();
        this.selectCustom(date, null);
        this.startDate = moment(date).format('MM/DD/YYYY');
        this.endDate = moment(date).format('MM/DD/YYYY');
        this.stringValue = 'Today';
        break;
      }
      case DateRangePresets.Tomorrow: {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.startDate = moment(tomorrow).format('MM/DD/YYYY');
        this.endDate = moment(tomorrow).format('MM/DD/YYYY');
        this.selectCustom(tomorrow, null);
        this.stringValue = 'Tomorrow';
        break;
      }
      case DateRangePresets.Yesterday: {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        this.startDate = moment(yesterday).format('MM/DD/YYYY');
        this.endDate = moment(yesterday).format('MM/DD/YYYY');
        this.selectCustom(yesterday, null);
        this.stringValue = 'Yesterday';
        break;
      }
      case DateRangePresets.CurrentCalendarWeek: {
        this.setCalendarRange(0, 'weeks');
        this.stringValue = 'This Week';
        break;
      }
      case DateRangePresets.LastCalendarWeek: {
        this.setCalendarRange(1, 'weeks');
        this.stringValue = 'Last Week';
        break;
      }
      case DateRangePresets.NextCalendarWeek: {
        this.setCalendarRange(-1, 'weeks');
        this.stringValue = 'Next Week';
        break;
      }
      case DateRangePresets.Previous: {
        this.numberOf = 'Past Number of';
        this.selectedPeriodType = this.selectedPeriodType
          ? this.selectedPeriodType
          : ({
              id: '1',
              name: 'Days',
            } as DropdownItem);
        this.selectedNumberOf = this.selectedNumberOf ? this.selectedNumberOf : '';
        this.stringValue = this.stringValue ? this.stringValue : '';
        this.selectedNumberOfChange();
        break;
      }
      case DateRangePresets.Upcoming: {
        this.numberOf = 'Upcoming number of';
        this.selectedPeriodType = this.selectedPeriodType
          ? this.selectedPeriodType
          : ({
              id: '1',
              name: 'Days',
            } as DropdownItem);
        this.selectedNumberOf = this.selectedNumberOf ? this.selectedNumberOf : '';
        this.stringValue = this.stringValue ? this.stringValue : '';
        this.selectedNumberOfChange();
        break;
      }
      case DateRangePresets.Custom: {
        this.setCustomDates();
        break;
      }
      case DateRangePresets.DateRange: {
        this.startDate = '';
        this.endDate = '';
        this.stringValue = '';
        this.onDateRangeChange();
        break;
      }
      case DateRangePresets.All: {
        this.startDate = '';
        this.endDate = '';
        this.stringValue = '';
        break;
      }
      default: {
        this.setCustomDates();
        break;
      }
    }
    this.emit();
  }

  private setCalendarRange(positionFromCurrent: number, range: any, endDate: Date | null = null, isUpcoming = false) {
    this.clearCalendar();
    const customClasses = [];
    let date = !isUpcoming
      ? new Date(moment().subtract(positionFromCurrent, range).startOf(range).format('YYYY-MM-DD'))
      : new Date(moment().subtract(positionFromCurrent, range).endOf(range).format('YYYY-MM-DD'));
    date = new Date(date.getTime() + new Date().getTimezoneOffset() * 60 * 1000);
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    customClasses.push('select-start-custom');
    const sd = {
      date,
      classes: customClasses,
    } as DatepickerDateCustomClasses;
    this.selectedDays.push(sd);
    endDate = endDate
      ? endDate
      : new Date(moment().subtract(positionFromCurrent, range).endOf(range).format('YYYY-MM-DD'));
    endDate = new Date(endDate.getTime() + new Date().getTimezoneOffset() * 60 * 1000);
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0);
    this.startDate = moment(date).format('MM/DD/YYYY');
    this.endDate = moment(endDate).format('MM/DD/YYYY');
    this.selectDateRange(endDate, null);
  }

  private setRangeValue() {
    this.bsInlineRangeValue = undefined;
    if (this.selectedDates && this.selectedDates.length) {
      const selectedDates = this.selectedDates.filter((cc) => !cc.classes.includes('holiday'));
      this.bsInlineRangeValue = selectedDates?.length ? selectedDates[0].date : undefined;
    }
    if (this.dateRangePresets === this.dateRangePresetsEnum.Custom) {
      this.stringValue = '';
      this.selectedCustomDays = this.selectedDays.sort(this.sortSelectedDays).map((sd, index) => {
        const strDate = moment(sd.date).format('MM/DD/YYYY');
        this.stringValue = `${this.stringValue} ${index === this.selectedDays.length - 1 ? strDate : strDate + ','}`;
        return {
          id: index.toString(),
          name: strDate,
        } as DropdownItem;
      });
    }
  }

  private setHolidays() {
    if (!this.holidays) {
      return;
    }
    this.dateHoliday = [];
    this.selectedDates = this.selectedDates.filter((cc) => !cc.classes.includes('holiday'));
    this.holidays.forEach((holiday) => {
      const startTime = Date.parse(holiday.StartTime);
      const endTime = Date.parse(holiday.EndTime);
      if (!isNaN(startTime) && !isNaN(endTime)) {
        const days = this.getDaysBetweenTwoDates(new Date(startTime), new Date(endTime));
        const date = new Date(startTime);
        for (let index = 0; index < days; index++) {
          date.setDate(date.getDate() + index);
          const dateNow = new Date();
          let holidayTemp: Date;
          if (holiday.Repeat) {
            for (let year = dateNow.getFullYear() - 3; year < dateNow.getFullYear() + 3; year++) {
              holidayTemp = new Date(year, date.getMonth(), date.getDate());
              this.addHoliday(holidayTemp);
            }
          } else {
            holidayTemp = new Date(new Date().getFullYear(), date.getMonth(), date.getDate());
            this.addHoliday(holidayTemp);
          }
        }
      }
    });
    this.selectedDates = this.selectedDates.concat(this.dateHoliday);
  }

  private getDaysBetweenTwoDates(dateFrom: Date, dateTo: Date): number {
    const differenceInTime = dateTo.getTime() - dateFrom.getTime();
    return Math.ceil(differenceInTime / (1000 * 3600 * 24));
  }

  private addHoliday(date: Date) {
    const datepickerDateHolidayClass = {
      date,
      classes: ['holiday'],
    } as DatepickerDateCustomClasses;
    this.dateHoliday.push(datepickerDateHolidayClass);
  }

  private bsValueChange(date: Date) {
    const selectedDay: DatepickerDateCustomClasses | null = null;
    this.selectedDates = this.selectedDates.filter(
      (cc) =>
        !cc.classes.includes('select-custom-day') &&
        !cc.classes.includes('select-start-custom') &&
        !cc.classes.includes('select-end-custom') &&
        !cc.classes.includes('in-range-custom'),
    );
    switch (this.keyCode) {
      case '-1': {
        break;
      }
      case 'Shift': {
        this.selectDateRange(date, selectedDay);
        break;
      }
      case 'Control': {
        this.selectCustom(date, selectedDay);
        break;
      }
      default: {
        this.selectDefault(date, selectedDay);
        break;
      }
    }
    this.keyCode = '';
    this.dateChanged.emit();
  }

  private selectCustom(date: Date, selectedDay: DatepickerDateCustomClasses | null) {
    if (date) {
      selectedDay = {
        date,
        classes: ['select-custom-day'],
      } as DatepickerDateCustomClasses;
    }
    if (!selectedDay) {
      return;
    }
    this.selectedDays.push(selectedDay);
    this.selectedDates = this.selectedDates.concat(this.selectedDays);
  }

  private selectDateRange(date: Date, selectedDay: DatepickerDateCustomClasses | null) {
    if (date) {
      selectedDay = {
        date,
        classes: ['in-range-custom'],
      } as DatepickerDateCustomClasses;
    }
    this.setSelectedDate(selectedDay);
  }

  private selectDefault(date: Date, selectedDay: DatepickerDateCustomClasses | null) {
    if (date) {
      selectedDay = {
        date,
        classes: ['select-custom-day'],
      } as DatepickerDateCustomClasses;
    }
    if (!selectedDay) {
      return;
    }
    this.clearCalendar();
    this.selectedDays.push(selectedDay);
    this.selectedDates = this.selectedDates.concat(this.selectedDays);
  }

  private setSelectedDate(selectedDay: DatepickerDateCustomClasses | null) {
    if (!selectedDay) {
      return;
    }
    let lastDate = this.selectedDays.pop();
    lastDate = lastDate ? lastDate : selectedDay;
    this.clearCalendar();
    const dateFrom = lastDate.date.getTime() <= selectedDay.date.getTime() ? lastDate.date : selectedDay.date;
    const dateTo = dateFrom.getTime() === lastDate.date.getTime() ? selectedDay.date : lastDate.date;
    let date = new Date(dateFrom);
    while (date.getTime() <= dateTo.getTime()) {
      const customClasses = [];
      if (date.getTime() === dateFrom.getTime()) {
        customClasses.push('select-start-custom');
      }
      if (date.getTime() === dateTo.getTime()) {
        customClasses.push('select-end-custom');
      }

      if (date.getTime() === dateFrom.getTime() || date.getTime() === dateTo.getTime()) {
        customClasses.push('select-custom-day');
      }

      if (date.getTime() > dateFrom.getTime() && date.getTime() < dateTo.getTime()) {
        customClasses.push('in-range-custom');
      }
      const sd = {
        date,
        classes: customClasses,
      } as DatepickerDateCustomClasses;
      this.selectedDays.push(sd);
      date = new Date(date);
      date.setDate(date.getDate() + 1);
    }
    this.selectedDates = this.selectedDates.concat(this.selectedDays);
  }

  private sortedSelectedDates() {
    if (!this.selectedDates || !this.selectedDates.length) {
      return null;
    } else {
      return this.selectedDates.sort(this.sortSelectedDays).map((sd) => sd.date);
    }
  }

  private sortSelectedDays(a: DatepickerDateCustomClasses, b: DatepickerDateCustomClasses) {
    if (a.date.getTime() < b.date.getTime()) {
      return -1;
    }
    if (a.date.getTime() > b.date.getTime()) {
      return 1;
    }
    return 0;
  }

  private sortDays(a: Date, b: Date) {
    if (a.getTime() < b.getTime()) {
      return -1;
    }
    if (a.getTime() > b.getTime()) {
      return 1;
    }
    return 0;
  }

  private setCalendarRangeCount(
    positionFromCurrent: number,
    range: any,
    endDate: Date | null = null,
    isUpcoming = false,
  ) {
    this.clearCalendar();
    const customClasses = [];

    let date: any;
    switch (range) {
      case 'days': {
        date = !isUpcoming
          ? new Date(
              moment()
                .subtract(positionFromCurrent - 1, range)
                .format('YYYY-MM-DD hh:mm:ss a'),
            )
          : new Date(
              moment()
                .subtract(positionFromCurrent + 1, range)
                .format('YYYY-MM-DD hh:mm:ss a'),
            );
        break;
      }
      case 'weeks': {
        date = !isUpcoming
          ? new Date(moment().subtract(positionFromCurrent, range).format('YYYY-MM-DD hh:mm:ss a'))
          : new Date(moment().subtract(positionFromCurrent, range).format('YYYY-MM-DD hh:mm:ss a'));
        break;
      }
      case 'months': {
        date = !isUpcoming
          ? new Date(moment().subtract(positionFromCurrent, range).format('YYYY-MM-DD hh:mm:ss a'))
          : new Date(moment().subtract(positionFromCurrent, range).format('YYYY-MM-DD hh:mm:ss a'));
        break;
      }
    }

    date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    customClasses.push('select-start-custom');
    const sd = {
      date,
      classes: customClasses,
    } as DatepickerDateCustomClasses;
    this.selectedDays.push(sd);

    endDate = endDate
      ? endDate
      : new Date(moment().subtract(positionFromCurrent, range).endOf(range).format('YYYY-MM-DD'));
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0);

    this.startDate = moment(date).format('MM/DD/YYYY');
    this.endDate = moment(endDate).format('MM/DD/YYYY');
    this.selectDateRange(endDate, null);
  }

  private setCustomDates() {
    if (this.dates && this.dates.length) {
      this.dateRangePresets = DateRangePresets.Custom;
      this.selectedCustomDays = this.dates.sort(this.sortDays).map((d, index) => {
        const strDate = moment(d).format('MM/DD/YYYY');
        return {
          id: index.toString(),
          name: strDate,
        } as DropdownItem;
      });
      this.selectedCustomDaysChange(this.selectedCustomDays);
    } else {
      this.selectedCustomDays = [];
      this.startDate = '';
      this.endDate = '';
    }
    this.selectedCustomDaysChange(this.selectedCustomDays);
  }
}
