import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, mergeMap } from 'rxjs';
import { AuthState, selectAccountName } from '../auth.state';

/**
 * Tenant interceptor that applies the account name of the current authenticated user to each API request.
 */
export const tenantInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn) => {
  return inject(Store<AuthState>)
    .select(selectAccountName)
    .pipe(
      first(),
      mergeMap((accountName) => {
        if (!request.url.includes('auth')) {
          const headers = request.headers.append('x-tenant-id', accountName);
          request = request.clone({
            headers,
          });
        }

        return next(request);
      }),
    );
};
