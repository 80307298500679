import { LocalStorageService } from '@abbadox-monorepo/core-utils';
import { LOCAL_STORAGE_KIOSK_TOKEN_KEY } from '@abbadox-monorepo/kiosk-core-constants';
import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map, take } from 'rxjs';

export const authGuard: CanMatchFn = () => {
  const storage = inject(LocalStorageService);
  const router = inject(Router);

  return storage.getItem(LOCAL_STORAGE_KIOSK_TOKEN_KEY).pipe(
    map((token) => {
      if (!token) {
        return router.parseUrl('/login');
      }

      return true;
    }),
    take(1),
  );
};
