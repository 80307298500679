import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs';
import { IdleService } from './idle.service';
import { IdleActions, IdleState, selectIdleTimeoutSeconds } from './idle.state';

// Starts the idle timeout
export const startIdling$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store<IdleState>), idleService = inject(IdleService)) =>
    actions$.pipe(
      ofType(IdleActions.startIdleWatch),
      withLatestFrom(store.select(selectIdleTimeoutSeconds)),
      tap(([, idleTimeoutSeconds]) => {
        idleService.setup({ idleTimeoutSeconds });
        idleService.startIdling();
      }),
    ),
  { functional: true, dispatch: false },
);

// Stops the idle timeout
export const stopIdling$ = createEffect(
  (actions$ = inject(Actions), idleService = inject(IdleService)) =>
    actions$.pipe(
      ofType(IdleActions.stopIdleWatch),
      tap(() => {
        idleService.stopIdling();
      }),
    ),
  { functional: true, dispatch: false },
);
